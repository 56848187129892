@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

@import '~okraui-ts/dist/styles/index.scss';
@import './colors';

.popper-options {
  z-index: 9999999 !important;
}

@function convertPxToRem($value) {
  $remValue: calc($value / 10) + rem;
  @return $remValue;
}

.code {
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0;
  font-family: 'JetBrainsMono' !important;
  font-weight: 400;
}

*,
*::before,
*::after,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  vertical-align: baseline;
  font-family: 'StudioPro' !important;
}

code {
  font-family: 'JetBrainsMono', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif !important;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}

.cap {
  text-transform: capitalize;
}
.cursor {
  cursor: pointer;
}
.full-button {
  width: 100%;
}
.primary {
  color: $primary;
}
.primary-link {
  color: $primary;

  &:hover,
  &:visited {
    color: $primary;
  }
}
.input-columns {
  display: grid;
  grid-gap: 16px;

  &-2 {
    grid-template-columns: repeat(2, 1fr);
    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
      margin-bottom: 16px;
    }
  }
}
.page-container {
  height: fit-content;
  padding-top: 24px;
  .title {
    margin-bottom: 1rem;
  }
  .subtitle {
    margin-bottom: 2rem;
  }
  &__back {
    margin-bottom: 24px;

    svg {
      path {
        stroke: $primary;
      }
    }
  }
  &__header {
    @include display-flex(flex-start);
    margin: 16px 0 24px;
    @include respond(phone) {
      flex-direction: column;
    }
    h3 {
      margin-right: 8px;
    }
  }
  &__copy {
    @include display-flex(flex-start);

    .copy-card {
      margin-left: 4px;
    }
  }
}

.inner-page-container {
  .row {
    @include display-flex(space-between, flex-start);
    .copy-card {
      color: $dark-grey;
      font-size: 14px;
      margin-top: 8px;
    }
    .buttons {
      @include display-flex(space-between);
      &.multi {
        button:first-child {
          margin-right: 8px;
        }
        @include respond(phone) {
          flex-direction: column;
          button:first-child {
            margin-right: 0;
            width: 100%;
            margin-bottom: 12px;
          }
        }
      }
    }
    @include respond(tab-port) {
      flex-direction: column;
      .buttons {
        margin-top: 12px;
      }
    }
  }
  .breadcrumbs-container {
    margin-bottom: 1rem;
  }
  h4, // remove this or fix everywhere else?
  .title {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .semibold {
    font-size: 18px;
  }
  // p, //guy, this thing is messing a lot of things up
  .subtitle {
    margin: 1rem 0;
  }
}

.center {
  margin: auto;
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.table-empty-state {
  @include display-flex(center, center, column);
  max-width: 330px;
  margin: auto;

  & > * {
    display: flex;
    white-space: pre-wrap;
    text-align: center;
  }

  &__icon {
    width: 32px;
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 4px;
  }
}

.error {
  color: $critical;
}

.button {
  &:disabled {
    svg {
      path {
        stroke: $grey;
      }
    }
  }
}

.big-large-screen {
  @include respond(tab-port) {
    display: none !important;
  }
}

.breadcrumbs-container {
  @include respond(phone) {
    width: 100%;
    //  .breadcrumbs-item {
    //    width: inherit;
    //  }
  }
}

.dark-grey-font {
  color: $dark-grey;
}

.dark-font {
  color: $black;
}

// typography
.action-text {
  font-size: 14px;
  letter-spacing: -0.006px;

  &-bold,
  &.bold {
    font-weight: 700;
    line-height: 16px;
  }
  &-semibold,
  &.semibold {
    font-weight: 600;
    line-height: 18px;
  }
  &-medium,
  &.medium {
    font-weight: 500;
    line-height: 18px;
  }
}

p,
.p {
  line-height: 26px;
  letter-spacing: -0.01px;
  font-weight: 400;

  &-small,
  &.small {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.006px;
  }
  &-semibold,
  &.semibold {
    font-weight: 600;
  }
  &-medium,
  &.medium {
    font-weight: 500;
  }
}

@import '../layouts/layouts';
@import '../components/components';
@import '../pages/pages';

.table-topbar-info,
.drawer-header {
  h4 {
    font-weight: 600;
    font-size: 2rem;
    letter-spacing: -0.05rem;
  }
}

.api-keys-copy-element {
  @include display-flex(center, center);
  cursor: pointer;
  p {
    color: $green-700;
  }
}

.app-link-trailing-elements {
  @include display-flex(center, center);
  height: 100%;

  .divider {
    height: 100%;
    width: 1px;
    background-color: $grey-variant-200;
    margin-left: 4px;
  }
}

.drawer-header {
  .drawer-header-close {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.tabs-container .tabs-content {
  padding: 0 !important;
}

.page-title {
  margin-bottom: 1rem;

  @include respond(phone) {
    padding: 0 16px;
  }

  h3,
  h4 {
    margin-bottom: 1rem;
  }

  .split {
    @include display-flex();
    &.spaced {
      margin: 18px 0;
    }
    &.links {
      > div {
        margin-right: 12px;

        > p {
          font-size: 14px;
        }
      }
    }

    &.copy {
      &:hover {
        cursor: pointer;
      }
      > div {
        margin-right: 8px;
      }
    }
  }
  .btns {
    button:first-child {
      margin-right: 16px;
    }
  }
}

.okra-popup {
  .close-button {
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &__header {
    h4 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__footer {
    button.neutral-button {
      background-color: $white;
      border: 1px solid $grey-variant-300;

      &:hover {
        background-color: $white;
      }

      &:active {
        box-shadow: inset 0px -3px 2px rgba(34, 44, 59, 0.24);
      }

      &:focus {
        box-shadow: 0 0 0 3px rgba(0, 128, 89, 0.16);
      }

      .button-text {
        color: $grey-800;
      }
    }
  }
}

// hack for table buttons as action roles
.table-action-button {
  margin-top: -5px;
}

.shimmer {
  position: relative;
  overflow: hidden;
  background-color: $grey-variant-200 !important;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  background-image: none !important;

  &::after {
    animation: waveKeyframe 1.6s linear 0.5s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      $grey-variant-300,
      transparent
    );
    content: '';
    position: absolute;
    transform: translateX(-100%); /* Avoid flash during server-side hydration */
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

@keyframes waveKeyframe {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.support-modal {
  @include respond(tab-port) {
    padding: 0 !important;

    .okra-popup__content {
      height: 450px;
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 0%) scale(1) !important;
      top: unset;
    }
  }
}

.support-modal {
  @include respond(phone) {
    .button {
      padding: 0 24px;
      height: 48px !important;

      .button-text {
        font-size: 1.6rem;
      }
    }
  }

  &__loading {
    .okra-popup__body {
      @include display-flex(center, center);
    }
  }

  &__disabled {
    button.primary-button {
      border: 0 !important;
      background-color: $grey-100 !important;
      box-shadow: none !important;
      pointer-events: none;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  .okra-popup__body {
    height: 100%;
    flex: 1;
  }
}

.toaster-container {
  all: unset;
  @include display-flex(flex-start, flex-start);
  width: 360px !important;
  box-shadow: $toaster-shadow !important;
  border-radius: 12px !important;
  background: $white !important;
  z-index: 999999;
}
