.passwordChecker {
  position: relative;
  width: 500px;
  margin-top: 0px;
  padding-bottom: 0px;
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;

  @include respond(phone) {
    width: 100%;
  }

  &.revealOptions {
    margin-top: -10px;
    padding-bottom: 20px;
    height: 52px;
    transition: all 0.5s ease-in;

    @include respond(phone) {
      height: 80px;
    }

    @media (max-width: 300px) {
      height: 90px;
    }
  }

  &__bars {
    display: flex;
    gap: 8px;

    @include respond(phone) {
      gap: 2%;
    }

    &__red,
    &__yellow,
    &__green {
      position: relative;
      display: flex;
      gap: 8px;

      @include respond(phone) {
        width: 32%;
        gap: 5%;
      }
      & > span {
        position: relative;
        width: 48px;
        height: 5px;
        border-radius: 5px;
        background: $icon-grey;

        @include respond(phone) {
          width: 33%;
        }
      }
    }

    &__red {
      &.weakStrength {
        span {
          background: red;
        }
      }
    }

    &__yellow {
      &.goodStrength {
        span {
          background: orange;
        }
      }
    }

    &__green {
      &.strongStrength {
        span {
          background: $okra-green;
        }
      }
    }
  }

  &__citeria {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @include respond(phone) {
      flex-direction: column;
    }

    &__text {
      & > P {
        font-size: 12px;
        color: $grey;
      }
    }

    &__list {
      display: flex;
      gap: 10px;

      @include respond(phone) {
        margin-top: 5px;
        justify-content: space-between;
        padding: 0 2px;
      }

      @media (max-width: 300px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 10px 10px;
        justify-content: space-between;
        margin-top: 0;
        padding: 0;
      }

      & > P {
        font-size: 12px;
        color: $grey;
        display: flex;
        align-items: flex-start;
        gap: 5px;

        @include respond(phone) {
          gap: 5%;
        }

        & > span {
          & > svg {
            width: 15px;

            @include respond(phone) {
              width: 10px;
            }
          }
        }
      }
    }
  }
}
