//global table fix, I will find the source and fix it.
td {
  p {
    font-size: 14px;
    margin: 0 !important; //i will find the source and fix it
  }
}
small {
  font-size: smaller;
}
.dark-grey {
  color: $dark-grey;
}

.textfield-control .textfield-container .textfield-addon--right {
  cursor: pointer;
}

@import './Payments/payments';
@import './Dashboard/dashboard';
@import './Data/data';
@import './Settings/settings';
@import './Business/business';
@import './Developers/developers';
