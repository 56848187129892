.stripe-form {
  .StripeElement {
    display: block;
    border-radius: 8px;
    background: $grey-variant-50;
    padding: 17px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $grey-800;

    &--focus {
      background: $white;
      border: 1.5px solid v$green-700;
      box-shadow: 0px 0px 0px 3px rgba(0, 128, 89, 0.24);
    }
    &--invalid {
      background: $white;
      border: 1.5px solid $critical-500;
      box-shadow: 0px 0px 0px 3px rgba(224, 38, 41, 0.24);
    }
    &:disabled {
      color: $grey-800;
      background: $grey-400;
    }
  }
  p {
    padding: 0 16px 8px;
    color: $grey-400;
  }
}
