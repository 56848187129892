.products-container {
  h3.title {
    margin-bottom: 1rem;
  }
  .subtitle {
    margin-bottom: 2rem;
  }
  .td-render {
    p.cap {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
@import './Overview/overview';
@import './Records/records';
@import './Auths/auths';
@import './Identities/identity';
@import './Balances/balance';
@import './Transactions/tranactions';
@import './Income/income';
