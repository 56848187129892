.not-found {
  width: 415px;
  .card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    button {
      align-self: center;
      margin-top: 48px;
    }
    svg {
      margin-top: 12px;
    }
  }

  margin: auto;
  margin-top: 120px;
  @include respond(tab-port) {
    width: 90%;
  }
  @include respond(phone) {
    width: 100%;
  }
}
