.no-project {
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  justify-content: center;
  align-items: center;

  .empty-state {
    &__icon {
      width: auto;
    }
  }
}
.okra-popup .space-below {
  margin-bottom: 32px;
}
