.income {
  .infocard-table {
    &__row {
      grid-template-columns: 450px 1fr;
      @include respond(tab-land) {
        grid-template-columns: 350px 1fr;
      }
      @include respond(tab-port) {
        grid-template-columns: 1fr;
      }
    }
    p {
      @include display-flex(flex-start);
      svg {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }
  .mini-section {
    > div {
      display: flex;
      p:first-child {
        margin-right: 6px;
      }
    }
  }
}
