.clients {
  .split {
    display: flex;
    div:first-child {
      margin-right: 10px;
    }
  }
  .tr-action .primary-text-button svg {
    path {
      stroke: $primary;
    }
  }
  .tr-action .primary-text-button:disabled svg {
    path {
      stroke: $grey;
    }
  }

  .client-action-svg {
    path {
      fill: transparent !important;
      stroke: #009e74;
      background-color: red;
    }
  }
}
