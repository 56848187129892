.builder-custom {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &-content {
    margin-bottom: auto;
    padding: 0 20%;

    .title {
      margin-bottom: convertPxToRem(38);
      color: $grey-900;
    }

    .options {
      @include display-flex(flex-start, center);
      gap: convertPxToRem(8);

      .option {
        @include display-flex(center, center);
        height: convertPxToRem(40);
        padding: 0 convertPxToRem(12);
        border-radius: 100px;
        color: $grey-800;
        border: 1px solid $grey-variant-200;
        cursor: pointer;

        &.active {
          background-color: $grey-900;
          color: $white;
        }
      }
    }

    &__products {
      margin-top: convertPxToRem(80);

      .inner-content {
        @include display-flex(flex-start, flex-start);
        gap: 100px;

        &__recommended,
        &__addons {
          width: 345px;

          h4 {
            padding-bottom: convertPxToRem(24);
            border-bottom: 1px solid $grey-variant-200;
          }

          .product {
            @include display-flex(flex-start, center);

            svg {
              width: convertPxToRem(10);
              height: convertPxToRem(10);
              margin: 0;

              path {
                stroke: $green-700;
              }
            }

            &:not(:last-child) {
              margin-bottom: 24px;
            }

            .icon {
              width: convertPxToRem(20);
              height: convertPxToRem(20);
              background-color: $green-50;
              border-radius: 100px;
              @include display-flex(center, center);
              margin-right: convertPxToRem(16);
            }

            &:first-of-type {
              margin-top: convertPxToRem(24);
            }

            p {
              color: $grey-700;
            }
          }

          .free-tag {
            margin-left: convertPxToRem(16);
          }
        }
      }
    }

    &__alertbar {
      background-color: $info-100;
      @include display-flex(flex-start, center);
      padding: convertPxToRem(16);
      border-radius: 12px;
      margin-top: convertPxToRem(70);
      gap: convertPxToRem(8);

      p {
        color: $dark-blue-7;
      }
    }

    &__footer {
      @include display-flex(space-between, center);

      margin-top: convertPxToRem(44);
      @include display-flex();
      > div,
      Button {
        @include display-flex(center, center);
        Button:first-child {
          margin-right: 32px;
        }

        .button-text {
          @include display-flex(center, center);
        }
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 12px;
        path {
          stroke: $dark-grey;
        }
      }

      .edit-button {
        svg {
          path {
            stroke: $white;
          }
        }
      }
    }

    &__empty-state {
      color: $dark-grey;
      width: 400px;
      margin: 40% 0 10% 30%;
      text-align: center;
      svg {
        width: 32px;
        height: 32px;
        margin-bottom: 24px;
        path {
          stroke: $dark-grey;
        }
      }
    }
  }
}
