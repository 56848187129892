@import './PayoutFunds/payout-funds';

.single-payout-container {
  padding: 0;

  .card-header {
    @include display-flex();
    padding: 24px 32px;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 0;

    @include respond(tab-port) {
      padding: 0 0 20px;
      margin-bottom: 20px;
    }

    .link-header {
      &__title {
        color: $black;
        @include display-flex(flex-start, center);

        .pill {
          margin-left: 16px;
        }
      }

      &__actions {
        display: flex;

        .button {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .card-content {
    padding: 32px;
    display: block;

    @include respond(phone) {
      padding: 0;
    }

    & > div {
      border-right: 0;
      border-bottom: 1px solid $light-grey;
      padding: 32px 0;
      width: 100%;
      @include display-grid(4, 20px);

      @include respond(tab-port) {
        grid-template-columns: repeat(2, 1fr);
      }

      span {
        display: flex;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.payout-info {
  &__title {
    margin-bottom: 8px;
  }

  &__desc {
    margin-top: 4px;

    .caption {
      margin-left: 2px;
    }
  }
}

.payout-receipt {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -200;
  pointer-events: none;
  opacity: 0;
  padding: 24px 32px;
  max-width: 700px;
  margin: 0 auto;

  &__header {
    text-align: center;
    margin-bottom: 32px;

    h2 {
      padding-top: 24px;
    }

    img {
      height: 40px;
      width: 36px;
    }
  }

  &__body {
    p {
      margin-bottom: 16px;

      span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        display: inline-block;
        width: 200px;
        letter-spacing: -0.01px;
        color: $dark-grey;
      }
    }
  }

  &__footer {
    border-top: 0.8px solid $light-grey;
    padding-top: 32px;

    a {
      color: $primary;
    }

    p {
      display: inline-block;
      width: 60%;
      min-width: fit-content;
    }

    .right {
      width: 40%;
      text-align: right;
    }
  }
}
