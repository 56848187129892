.auth {
  &.register-success {
    height: 100vh;
    @include display-flex(center, center, column);

    .auth__logo {
      margin-bottom: auto;
      margin-right: auto;
      margin-top: 80px;
      margin-left: 160px;

      @include respond(phone) {
        display: none;
      }
    }

    .pending-content {
      @include display-flex(center, center, column);
      width: 650px;
      margin-bottom: auto;

      @include respond(phone) {
        width: 100%;
      }

      h4 {
        margin-bottom: 4px;
      }

      p {
        color: $grey-700;
        padding: 0 50px;

        @include respond(phone) {
          padding: 0 16px;
        }

        span {
          color: $grey-900;
        }
      }
    }
  }

  button.primary-button {
    width: 100%;
  }
  &__logo {
    width: 112px;
    margin-bottom: 30px;
    @include respond(phone) {
      margin-bottom: 25px;
      display: block;
      margin-right: auto;
    }
  }
  &__success-logo {
    margin-top: 85px - 35px;
    margin-bottom: 45px;
    @include respond(phone) {
      margin-bottom: 25px;
    }
  }
  .center-text {
    margin: auto;
    display: block;
    text-align: center;
    &.spaced {
      margin-top: 24px;
    }
    &.more-space {
      margin-top: 40px;
    }
  }
  .medium.center-text {
    margin-bottom: 40px;
  }
  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  &__center-title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-size: 16px;
  }
  &__center-subtitle {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: #737a91;
  }

  &__checkbox {
    .checkbox__label__content {
      max-width: 100% !important;
    }
    margin-bottom: 24px;
  }
  &__infotext {
    font-weight: 500;
    a {
      color: $primary;
    }
    &.plain {
      font-weight: 400;
    }
    &.spaced {
      margin-top: 40px;
    }
  }
  .success-card {
    text-align: center;
    p {
      margin-top: 24px;
      font-size: 16px;
      padding-bottom: 40px;
      border-bottom: 1px solid $light-grey;
    }
  }
  .split,
  &.split {
    @include display-flex(space-between, flex-start);

    @include respond(phone) {
      flex-wrap: wrap;

      .textfield-control {
        width: 100% !important;
      }
    }

    &.right_space {
      .textfield-control {
        width: 48%;
      }
    }
  }

  &__invitePassword {
    width: 100%;

    .passwordChecker__bars {
      &__red,
      &__yellow,
      &__green {
        gap: 8px;
        @include respond(phone) {
          width: 32%;
          gap: 5%;
        }
        & > span {
          width: 32px;
          height: 5px;
          @include respond(phone) {
            width: 33%;
          }
        }
      }
    }

    .passwordChecker__citeria {
      flex-direction: column;
      &__list {
        justify-content: space-between;
      }
    }

    &.revealOptions {
      margin-top: 0px;
      height: 70px;

      @media (max-width: 300px) {
        height: 100px;
      }
    }
  }
}

.login-layout {
  width: 415px;

  .auth__logo {
    @include respond(phone) {
      margin: 0 auto;
    }
  }

  .textfield-control {
    margin-bottom: 16px;
  }
  .card {
    width: 100%;
  }
  .md-button {
    margin-top: 16px;
    width: 91%;
  }
  &.invited {
    width: 420px;
  }
  &.pending {
    width: 640px;
    .pending-invite {
      text-align: center;
      svg {
        display: block;
        margin: 32px auto 40px auto;
      }
      h3 {
        margin-bottom: 18px;
      }
      p {
        color: $dark-grey;
        margin-bottom: 32px;
      }
    }
    .existing-team {
      h3 {
        margin-top: 32px;
        margin-bottom: 16px;
      }
      p.info-text {
        color: $dark-grey;
        margin-bottom: 40px;
      }
      button.primary-button.md-button {
        margin-bottom: 0;
      }
      .extratext {
        font-size: 14px;
        color: $dark-grey;
        a {
          color: $primary;
          font-weight: 500;
        }
      }
      &--box {
        border: 1px solid $light-grey;
        padding: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        > p {
          margin-top: 8px;
          margin-bottom: 24px;
        }
        > div {
          @include display-flex();
          > * {
            flex: 1;
          }
        }
        .avatar-container {
          width: 40px;
          height: 40px;
          min-width: 40px;
        }
        .image-stack *:not(:first-child) {
          width: 41px;
          height: 41px;
          margin-left: -10px;
          border: 1px solid white;
        }
      }
    }
  }
  flex: 1;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  @include respond(phone) {
    width: 100% !important;
    padding-top: 20px;
  }
  &.spaced {
    @include respond(phone) {
      padding: 20px;
    }
  }
  .error-txt {
    color: $critical;
    margin-bottom: 10px;
  }

  .input-container:not(:last-child) {
    margin-bottom: 20px;
  }
  .split {
    @include display-flex();
    a {
      margin-left: 60px;
    }
    margin: 24px 0;
    &.small {
      font-size: 14px;
      a {
        font-weight: 500;
      }
    }
    @include respond(phone) {
      a {
        margin-left: 30px;
      }
    }
  }
  button.primary-button {
    margin-bottom: 16px;
  }
  .space-below {
    margin-bottom: 16px;
  }
}

.register-layout {
  background-color: $bg-grey;
  min-height: 100vh;

  @media (min-height: 300px) and (max-height: 601px) {
    height: auto;
  }

  &__left {
    align-self: stretch;
    flex: 1;
    background-color: white;
    @include display-flex(center, center);
    padding: 5rem 0 10rem;

    @include respond(tab-land) {
      align-items: flex-start;
    }

    @include respond(phone) {
      padding: 16px !important;
    }

    .auth__infotext {
      color: $grey-700;
    }

    &-content {
      margin: 0 100px;
      width: 500px;
      padding: 20px 0;

      @include respond(tab-land) {
        margin: 0 auto;
      }

      @include respond(phone) {
        width: 100%;
        margin: 0;
        justify-content: flex-start;
      }
    }

    h4 {
      margin-bottom: 32px;
    }

    .textfield-control {
      margin-bottom: 20px;
    }
  }

  &__right {
    @include display-flex(center, center, column);
    padding: calc(5rem + 35px + 60px) 5rem 4rem;
    width: 50%;
    align-self: stretch;
    background-image: url('../../../assets/Images/onboarding-bg.png');

    @include respond(tab-land) {
      display: none;
    }

    .auth-more {
      circle {
        fill: $info-500;
      }
    }

    &-content {
      width: 500px;

      @media only screen and (max-width: 1278px) {
        width: 100%;
      }
    }
  }
  .auth__title,
  .auth-title {
    margin-bottom: 5px;
    color: $grey-900;
  }

  .auth__subtitle {
    color: $grey-700;
  }

  .popper-container {
    width: 100%;
    .input-container {
      margin-bottom: 0;
    }
  }

  .select-container,
  .input-container {
    margin-bottom: 24px;
  }
  button.primary-button {
    margin: 24px 0;
  }
  &__imgs {
    margin-top: 25px;
    margin-bottom: 80px;
  }
  @include respond(tab-land) {
    &__left {
      padding-left: 10rem;
      padding-right: 10rem;
    }
    &__right {
      padding-left: 7rem;
      padding-right: 7rem;
    }
  }
  @include respond(tab-port) {
    &__right {
      display: none;
    }
    &__left {
      padding: 5rem;
    }
  }
}

.new-password {
  .passwordChecker {
    max-width: 100%;

    &.revealOptions {
      margin-top: 0px;
      height: 70px;
    }

    &__bars {
      gap: 5px;

      @include respond(phone) {
        gap: 4px;
      }

      &__red,
      &__yellow,
      &__green {
        gap: 5px;

        @include respond(phone) {
          gap: 4px;
        }
        & > span {
          position: relative;
          width: 34px;

          @include respond(phone) {
            width: 38px;
          }
        }
      }
    }

    &__citeria {
      flex-direction: column;
      &__text {
        & > P {
          font-size: 15px;
          color: $grey;
        }
      }
      &__list {
        margin-top: 5px;

        & > P {
          justify-content: space-between;
        }
      }
    }
  }
}
