.records-table {
  &__customer-details {
    span {
      display: block;
    }
  }
}

.refund-modal-loading .okra-popup__content .okra-popup__body {
  @include display-flex(center, center);
}

.single-record-container {
  .payment-header__title {
    display: flex;
    align-items: center;

    h4 {
      margin: 0 16px 0 0;
    }
  }

  .payment-info {
    padding: 32px 0;
    border-bottom: 1px solid #dfe2e6;
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px 10px;

    &-item {
      &__title {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .additional-info {
    &-item {
      margin-top: 32px;

      &__title {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .payment-schedule {
    padding: 32px 0;
    border-bottom: 1px solid #dfe2e6;
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 32px;

    &-item {
      &__title {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .next-payment-container {
    margin-top: 32px;
  }

  .next-payment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 12px;

    &-item {
      &__title {
        display: block;
        margin-bottom: 8px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.initiate-debit-card {
  .card-content {
    display: block;
    padding: 0;
  }

  form {
    width: 100%;
  }
}

.initiate-debit-form {
  padding: 24px 24px 0;
  height: calc(100vh - 184px);
  overflow: auto;

  & > * {
    margin-bottom: 16px;
  }

  &__account {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 4px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }

    .account-logo {
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background: #dfe2e6;
    }
  }

  .input-container .textfield-container,
  .select-container .select-display,
  .radio-container--boxed input ~ .radio-display-container {
    height: 48px;
  }

  .account-debit .select-display {
    height: auto !important;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    margin-top: 32px;
    border-top: 1px solid #dfe2e6;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .bank-logs .okra-bank {
    width: 20px !important;
    height: 20px !important;
  }
}

.bulk-payments {
  &__content {
    padding: 24px;
  }
  .validation-error {
    background: #fbe4ec;
    border: 1.5px solid $critical;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
    @include display-flex(flex-start, flex-start);

    &__icon {
      display: flex;
      margin-right: 10px;
      min-width: 16px;
      width: 16px;
      margin-top: 2px;

      svg {
        width: 100%;
        height: auto;

        path {
          fill: $critical;
          fill-opacity: 1;
        }
      }
    }
  }
  .info-box {
    margin: 24px 0;
  }
  .file-info {
    @include display-flex;
    margin-top: 16px;
    text-transform: capitalize;

    &__delete {
      @include display-flex(flex-start);
      @include full-circle(15px);
      cursor: pointer;

      svg {
        width: 100%;
      }
    }
  }

  &__content {
    .file-uploader-dropzone {
      p {
        font-size: 13px;
      }
    }
  }
}

.review-csv-actions {
  @include display-flex(flex-start);
  margin-right: 0;

  .button {
    &:first-child {
      margin-right: 4px;
    }

    svg {
      path {
        stroke: $dark-grey;
      }
    }
  }
}

.bulk-payment-details {
  .page-container__header {
    margin-bottom: 4px;
  }

  &-card {
    margin-top: 24px;

    .card-header {
      font-size: 16px;
      color: $black;
      font-weight: 500;
      border-bottom: 1px solid $light-grey;
      padding-bottom: 16px;
    }

    .payment-details-item {
      @include display-flex(flex-start);
      margin-bottom: 16px;
      width: 100%;

      span {
        min-width: 188px;
        display: block;

        &:first-child {
          margin-right: 80px;
        }
      }

      .okra-bank {
        width: 24px !important;
        height: 24px !important;
        background-size: 20px;
      }
    }
  }
}

.review-bulk {
  padding: 0 !important;

  &:first-child,
  &:last-child {
    padding: 0;
  }

  &:first-child {
    .td-render {
      & > span {
        padding-left: 24px;
      }
    }
  }

  &:last-child {
    .td-render {
      & > span {
        padding-right: 24px;
      }
    }
  }

  .td-render {
    & > span {
      padding: 0 8px;
      height: 74px;
      @include display-flex(flex-start);
    }

    .duplicate {
      background: #fbe4ec;

      .tooltip-container {
        margin-left: 8px;

        svg {
          width: 16px;

          path {
            fill: #d12564;
          }
        }

        .tooltip-content {
          max-width: 280px;
        }
      }
    }
  }
}
