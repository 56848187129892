.onboarding {
  &-container {
    min-height: 100vh;
    @include display-flex(flex-start, flex-start, column);

    &.sla {
      background-color: $white;

      .sla-footer {
        background-color: white;
        position: sticky;
        bottom: 0px;
        padding: 12px 160px;
        width: 100%;
        @include display-flex(space-between, center);

        @include respond(tab-land) {
          padding: 10px;
          flex-wrap: wrap;
          justify-content: center;

          .button {
            width: 100%;
            margin-top: 18px;
          }
        }

        .checkbox {
          width: auto;
        }
      }

      .onboarding-container__header {
        background-color: white;

        .mobile-stepper {
          display: none;

          @include respond(tab-land) {
            display: flex;
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }

      .sla-content {
        padding: 0 160px;
        margin-top: 100px;
        width: 100%;

        .body-content {
          * {
            padding: 0 !important;
            width: 100%;
            max-width: 100% !important;
            margin: 0 !important;

            span {
              color: $grey-700 !important;
              font-size: 16px !important;
            }

            p:last-child {
              display: none;
            }

            strong {
              color: $grey-900 !important;
            }

            br {
              display: none;
            }
          }
        }

        @include respond(tab-land) {
          padding: 0 16px;
        }

        &__header {
          padding-bottom: 24px;
          border-bottom: 1px solid $grey-variant-200;
        }

        &__body {
          margin-top: 40px;
          @include display-flex(flex-start, flex-start);
          gap: 130px;

          &-left {
            width: 60%;

            @include respond(tab-land) {
              width: 100%;
            }

            p {
              color: $grey-700;
            }

            h3 {
              margin-bottom: 10px;
            }

            .body-section {
              margin-bottom: 50px;
              p {
                margin-top: 24px;
                color: $grey-700;
              }
            }
          }

          &-right,
          .sla-form-mobile {
            width: 40%;
            position: sticky;
            top: 100px;
            @include display-flex(flex-end, center);

            @include respond(tab-land) {
              display: none;
            }

            .sla-content__form {
              width: 400px;
              padding: 24px;

              .divider {
                width: 100%;
                height: 1px;
                background-color: $grey-variant-200;
                margin: 24px 0;
              }

              p.body3 {
                color: $grey-700;
                margin-bottom: 4px;
              }

              .support-button {
                padding: 0;

                &:hover,
                &:active,
                &:focus {
                  background-color: transparent;
                }
              }

              .send-button {
                width: 100%;
                margin-top: 12px;
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }

    &__header {
      height: 64px;
      width: 100%;
      @include display-flex(space-between);
      padding: 0 160px;
      position: sticky;
      top: 0;
      background: white;
      z-index: 9999;

      .divider {
        width: 1px;
        height: 32px;
        margin: 0 28px;
        background-color: $grey-variant-200;

        @include respond(tab-land) {
          display: none;
        }
      }

      @include respond(tab-land) {
        padding: 0 16px;
        background-color: white;
      }

      img {
        width: 69px;
      }
    }

    display: flex;
    background-color: $bg-grey;
    @include respond(phone) {
      background-color: none;
      flex-direction: column;
    }
  }

  &-stepper {
    width: 30%;
    padding-top: 8rem;
    padding-right: 6rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .auth__logo {
      margin-bottom: 10rem;
    }
    .stepper-container {
      min-width: 240px;
    }
    @include respond(tab-land) {
      width: unset;
      padding-right: unset;
      padding-left: 2rem;
    }
    @include respond(tab-land) {
      display: none;
    }
  }

  &-pages {
    flex: 1;
    background-color: white;
    width: 100%;
    height: 100%;

    @include respond(tab-land) {
      padding: 16rem 5rem;
    }
    @include respond(phone) {
      padding: 16px;
    }
  }

  &-landing {
    &__placeholder {
      width: 100%;
      margin-bottom: 60px;
    }
    a,
    a:link {
      color: $okra-green;
    }
    h4 {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 50px;
    }
    width: 550px;
    margin: auto;
    @include respond(tab-port) {
      margin-top: unset;
      width: 100%;
    }
  }

  &-layout__inner {
    width: 100%;
    height: 100%;
    padding: 80px 160px;
    @include display-grid(2, 56px 80px);

    @include respond(tab-land) {
      display: block;
      padding: 24px 0;
    }

    &--summary {
      max-width: 1200px;
      margin: auto;
      padding-top: 56px;
      grid-gap: 56px 160px;

      @include respond(tab-land) {
        padding: 0 0 56px;
      }

      @include respond(phone) {
        padding: 24px 0 56px;
      }
    }

    &-description {
      grid-column: 1/3;
      @include display-flex(space-between, flex-start);

      @include respond(tab-land) {
        margin-bottom: 40px;
      }

      p {
        max-width: 655px;
      }
    }

    &-action {
      @include respond(tab-land) {
        @include full-fixed;
        top: unset;
        padding: 16px;
        background-color: $white;
        z-index: 10;
      }

      .button {
        @include respond(tab-land) {
          width: 100%;
        }
      }
    }

    &-right {
      h4 {
        margin-bottom: 16px;
        color: $grey-900;
      }

      p,
      h4 {
        width: 500px;

        @include respond(phone) {
          width: 100%;
        }
      }

      p {
        color: $grey-700;
      }
    }

    &-left {
      @include display-flex(flex-end, flex-start);
    }

    &-form {
      width: 400px;

      @include respond(tab-land) {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        margin-bottom: 40px;

        .file-uploader__v2 {
          flex: 0;
          .file-uploader-dropzone {
            height: 48px;
          }
        }
      }

      .footer {
        margin-top: 40px;
        padding-bottom: 100px;
      }

      button {
        width: 100%;
      }

      .radio-group {
        margin-bottom: 24px;

        &__title {
          display: block;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 14px;
        }

        .radio {
          margin-bottom: 8px;

          &__label {
            width: 100%;
          }
        }
      }

      .textfield-control,
      .select-container {
        margin-bottom: 24px;
      }

      .split {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin-bottom: 2rem;
        @include respond(tab-port) {
          grid-template-columns: repeat(1, 1fr);
        }
        & > * {
          min-width: 100%;
        }
        .input-container {
          width: 100%;
        }
      }

      .row-line {
        margin-top: 40px;

        &:first-child {
          margin-top: 0;
        }

        &__header {
          @include display-flex(space-between, center);
          padding-bottom: 16px;
          border-bottom: 1px solid $grey-variant-200;

          .button {
            max-width: 74px;

            svg {
              path {
                fill: transparent !important;
              }
            }
          }
        }

        &__item {
          margin-top: 24px;

          label {
            color: $grey-700;
            margin-bottom: 4px;
            display: block;
          }
          a {
            color: $green-700;
          }

          &.uploaded-file {
            padding: 0 16px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid $grey-variant-200;
            @include display-flex(space-between, center);

            p {
              color: $grey-700;
            }

            svg {
              path {
                stroke: $grey-900;
              }
            }
          }
        }
      }
      .space {
        &-below {
          margin-bottom: 32px;
        }
        &-left {
          margin-left: 32px;
        }
        &-right {
          margin-right: 32px;
        }
        &-top {
          margin-top: 32px;
        }
      }
      .file-uploader {
        margin-top: 24px;
      }

      .group {
        margin: 32px 0;
        .light {
          color: $dark-grey;
          text-transform: capitalize;
        }
      }
    }
    @include respond(tab-land) {
      max-width: unset;
      width: 100%;
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      @include respond(phone) {
        margin-top: auto;
        button {
          margin-bottom: 20px;
        }
      }

      &.end {
        justify-content: flex-end !important;
      }
      button {
        width: 200px;
        @include respond(phone) {
          width: 100%;
        }
      }
    }
    &-info {
      @include display-flex(flex-start, flex-start);
      background: $info-100;
      padding: 20px;
      margin-top: 16px;
      border-radius: 8px;
      margin-bottom: 24px;

      p {
        color: $grey-700;
      }

      .icon {
        margin-right: 16px;
      }
    }
    .row {
      @include display-flex();
      .delete-icon {
        margin: 27px -40px 5px 5px;
      }
    }
  }
}

.sla-support-modal {
  &__disabled {
    button.primary-button {
      border: 0 !important;
      background-color: $grey-100 !important;
      box-shadow: none !important;
      pointer-events: none;
    }
  }

  .textfield-control div.textarea-container {
    height: 200px;
    resize: none;
  }

  button.neutral-button {
    background-color: $white;
    border: 1px solid $grey-variant-300;

    &:hover {
      background-color: $white;
    }

    &:active {
      box-shadow: inset 0px -3px 2px rgba(34, 44, 59, 0.24);
    }

    &:focus {
      box-shadow: 0px 0px 0px 3px rgba(0, 128, 89, 0.16);
    }

    .button-text {
      color: $grey-800;
    }
  }
}

.sla-page-loader {
  height: 100vh;
  @include display-flex(center, center, column);

  p {
    margin-top: 20px;
  }

  .okra-loader {
    width: 32px;
    height: 32px;
    border-top-color: $grey-900;
    border-right-color: $grey-900;
  }
}

.stepper-wrapper {
  margin-left: auto;

  .stepper-container-horizontal,
  .stepper-container__horizontal {
    @include respond(tab-land) {
      display: none;
    }
  }

  .mobile-stepper {
    display: none;
    @include respond(tab-land) {
      display: block;
    }
  }

  .stepper-container-horizontal
    .stepper-content.current
    .stepper-content__checkmark {
    background-color: $green-700 !important;
  }
}

.onboarding-close-modal {
  @include respond(phone) {
    @include display-flex(flex-end);
  }

  .okra-popup__content {
    @include respond(phone) {
      height: 50%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .okra-popup__body {
    height: 100%;
    @include display-flex(center, center, column);
  }

  p.body2 {
    text-align: center;
    color: $grey-700;
    padding: 0 48px;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .warning-icon {
    margin-top: 60px;
    margin-bottom: 30px;

    path {
      fill: $warning-500;
    }
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;

    cursor: pointer;

    margin: 20px;
    width: 24px;
    height: 24px;
  }
}

.sla-form-mobile {
  .okra-popup__body {
    padding: 0;
  }

  &__header {
    @include display-flex(space-between, center);
    height: 56px;
    padding: 0 24px;
    margin-bottom: 40px;
  }

  .sla-content__form {
    width: 100%;
    padding: 24px;

    .divider {
      width: 100%;
      height: 1px;
      background-color: $grey-variant-200;
      margin: 24px 0;
    }

    p.body3 {
      color: $grey-700;
      margin-bottom: 4px;
    }

    .support-button {
      padding: 0;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    }

    .send-button {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }
}

.file-uploader__error-messsage {
  color: $critical-500;
  margin-top: 4px;
}
