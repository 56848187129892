.global-search {
  .okra-popup {
    &__content {
      top: 0;
      transform: translate(-50%, 0);
      padding: 0px;
      padding-top: 20px;
      width: 80%;
      height: calc(100vh - 90px);
      overflow-y: auto;
      display: block;
      @include respond(phone) {
        width: 100%;
        height: 100%;
      }
    }
    &__body {
      padding: 0;
    }
  }
  &.active .okra-popup__content {
    transform: translate(-50%, 0);
  }
  .top-search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    .search-box {
      flex: 1;
      display: flex;
      border: 1px solid $gray;
      border-radius: 6px;
      background-color: $ash;
      align-items: center;
      form {
        display: flex;
        flex: 1;
      }
      input {
        flex: 1;
        border: 0;
        background-color: $ash;
        outline: none;
        font-size: 14px;
      }
      .fa-search {
        margin: 15px 12px 12px 14px;
        svg {
          width: 14px;
          height: 14px;
        }
      }
      .okra-loader {
        opacity: 1;
        border-top: 2px solid $dark-grey;
        border-right: 2px solid $dark-grey;
        margin-right: 10px;
      }

      .fa-close {
        color: #b9bcc9;
        font-size: 16px;
        padding-right: 10px;
        padding-left: 10px;
        cursor: pointer;
      }
      button {
        padding: 4px 8px;
        background-color: $okra-green;
        border: 1px solid $okra-green;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        color: white;
        text-align: center;

        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.006px;

        &:focus,
        &:hover {
          outline: none;
        }
      }
    }
    .close_search {
      width: auto;
      float: none;
      color: #b9bcc9;
      margin-left: 18px;
      font-size: 40px;
    }
  }

  .search_result {
    margin-top: 10px;
    .row-flex {
      display: flex;
      justify-content: space-between;
      p {
        color: black;
      }
      padding: 0px 32px;
    }
    .line {
      border-bottom: 1px solid $gray;
      margin-left: -30px;
      margin-right: -30px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .okra-btn.clear {
      width: auto;
      float: none;
      color: #b9bcc9;
      margin-left: 18px;
      color: $primary;
      font-size: 14px;
    }
  }
  .search_empty {
    margin: 80px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    text-align: center;
    i {
      background-color: $ash;
      padding: 15px;
      border-radius: 50%;
      color: $primary;
    }
    .search-text {
      margin-top: 40px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #222222;
    }
    .serach-info {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.01px;
      color: #737a91;
    }
  }
  .dif {
    padding-left: 33px;
    color: black;
  }
  .search-group {
    &-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.01px;
      color: #737a91;
      text-transform: capitalize;
      margin-bottom: 5px;
      padding: 0 32px;
    }
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 32px;
      &:hover {
        background-color: $ash;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $gray;
      }
      div {
        display: flex;
        align-items: center;
      }
      em {
        font-weight: 600;
        font-style: normal;
      }
      &-label {
        background-color: #ffffff;
        border: 1px solid $gray;
        border-radius: 6px;
        font-size: 0;
        padding: 5px;
        color: #b9bcc9;
        margin-right: 24px;
      }
      p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: -0.01px;
        color: #222222;
        margin-bottom: 0;
      }
      &-action {
        color: #737a91;
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}
