@import './Plans/plan';

@mixin galaxy-fold {
  @media (max-width: 280px) {
    border: none;
    display: block;
    margin-bottom: 1em;
  }
}

.billing {
  .flex {
    @include display-flex(flex-start, center);
    .caption {
      margin-bottom: 0;
      margin-left: 4px;
    }
  }
  &--color {
    &.primary {
      color: $primary;
    }
    &.dark-grey {
      color: $dark-grey;
    }
  }
  &--border-horizontal {
    border-bottom: 1px $light-grey solid;
    width: 100%;
    margin: 16px 0;
  }
  &--border-vertical {
    border-right: 1px $light-grey solid;
    &.height {
      min-height: 339px;
    }
    &.mid {
      @include respond(tab-port) {
        border-right: none;
      }
    }
    &.land {
      @include respond(tab-land) {
        border-right: none;
      }
    }
    &.small {
      @include galaxy-fold();
    }
  }
  &--flex {
    @include display-flex(space-between, flex-start);
    &.port {
      @include respond(tab-port) {
        &.block {
          display: block;
        }
        &.wrap {
          flex-wrap: wrap;
        }
      }
    }
  }
  &--title {
    margin: 0.4em 0 1.5em;
  }
  &-top-card {
    margin-top: 1.2em;
    border: 1px $light-grey solid;
    padding: 0 !important;

    &__plan-wallet {
      @include respond(tab-port) {
        width: 100%;
      }
      .growth-plan {
        flex-wrap: wrap;
        margin-bottom: 0.9em;
      }
      padding: 2em;
      width: 50%;
      .action-text,
      .caption {
        margin-bottom: 1em;
      }

      .wallet-balance {
        @include display-flex(unset, flex-start);
        @include respond(tab-land) {
          display: block;
        }
        @include galaxy-fold();
        .balance {
          @include respond(tab-land) {
            margin-bottom: 0.5em;
            width: 100%;
          }
          @include galaxy-fold();
          padding-right: 3em;
          margin-right: 3em;
        }
      }
      .primary-button {
        margin: 1.5em 0;
      }
    }
  }

  &-bottom-card {
    border: 1px $light-grey solid;
    margin-top: 2em;
    &__top-item {
      @include respond(tab-port) {
        p {
          margin-bottom: 1em;
          margin-right: 1em;
        }
      }
    }

    &__bank-holder {
      @include display-grid(3, 2em);
      grid-auto-rows: auto;
      @include respond(big-desktop) {
        @include display-grid(4, 2em);
      }
      @include respond(tab-land) {
        @include display-grid(2, 2em);
      }
      @include respond(tab-port) {
        display: block;
      }
      .bank-card {
        border: 1px $light-grey solid;
        @include respond(tab-land) {
          margin-top: 18px;
        }
        .okra-bank-details {
          p {
            text-transform: capitalize;
          }
          .caption {
            color: $dark-grey;
          }
        }
        .card-details {
          @include display-flex(space-between, flex-start);

          .pill {
            margin-left: 8px;
          }

          .card-expiry {
            display: block;
            margin-top: 4px;

            &.expired {
              color: $critical;
            }
          }
        }

        .card-footer {
          @include display-flex;
          #footer-svg {
            margin-right: 0.7em;
          }
        }
      }
    }
  }
  &__table {
    .table-pdf {
      cursor: pointer;
    }
  }
}
