.audit-logs {
  .logs-card {
    padding: 0;
    margin-top: 2em;
    border: 1px solid $light-grey;
    small {
      color: $dark-grey;
    }
    &--header {
      border-bottom: 1px solid $light-grey;
      padding: 2.1em;
      @include display-flex('');
      .header-name {
        margin-left: 1.5em;
      }
    }
    &--body {
      padding: 1em 2em 2.1em;
      .body-top {
        @include display-flex();
        flex-wrap: wrap;
        .regular {
          margin-right: 1em;
        }
        .top-header {
          color: $dark-grey;
          margin-top: 1em;
        }
      }
      .body-bottom {
        margin-top: 3em;
        h4 {
          margin-bottom: 0.8em;
        }
        .bottom-action {
          margin-bottom: 1.2em;
          .regular {
            text-transform: capitalize;
          }
        }
        .bottom-border {
          margin-bottom: 1.5em;
          border-bottom: 1px solid $light-grey;
        }
      }
    }
  }
}
