.base-header {
  @include display-flex();
  width: 100%;
  padding: 16px 24px;
  margin-bottom: auto;

  &-close {
    @include display-flex();
    color: $grey-800;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 9px;
      path {
        stroke: $grey-800;
      }
    }
  }
}

.edit-header {
  @include display-flex;
  @include full-fixed;

  z-index: 100;
  height: 48px;
  background: $white;
  border-bottom: 1px solid $grey-variant-200;
  padding-right: 24px;
  padding-left: 16px;

  div:not(.edit-header-links):not(.tooltip-content) {
    @include display-flex;
    height: 100%;
  }

  .mini-section {
    padding: 8px 24px 8px 0;
    margin-right: 24px;
    border-right: 1px solid $grey-variant-200;
    position: relative;

    > svg:first-child {
      height: 32px;
      width: 32px;
    }

    &.not-left {
      padding-left: 0;
    }
  }

  &-links {
    display: flex;
    align-items: flex-end;
    height: 100%;

    a,
    a:link {
      margin-right: 40px;
      color: $grey-700;
      height: 75%;
      font-size: convertPxToRem(14);

      &.active {
        color: $primary;
        border-bottom: 1px solid $primary;
        font-weight: 600;
      }
    }
  }
  &-close {
    color: $grey-800;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 9px;
      path {
        stroke: $grey-800;
      }
    }
  }

  .tooltip-content {
    padding: 4px 12px;
  }

  .tooltip-inner {
    color: $grey-900;
  }

  button.orient {
    width: convertPxToRem(32);
    height: convertPxToRem(32);
    padding: 0;
    border-radius: 100px;

    &:first-child {
      margin-right: 16px;
    }

    &:hover {
      background-color: $green-50;
    }

    &.active {
      background-color: $green-100;
      svg {
        width: convertPxToRem(16);
        height: convertPxToRem(16);

        path {
          stroke: $green-800;
        }
      }
    }
  }
}

.warning-note {
  text-align: center;
}

.warning-note + .checkbox-container {
  padding-left: 45px;
  margin-top: 12px;
}

.publish-modal {
  h5.semibold {
    margin-bottom: convertPxToRem(8);
  }
  .details {
    @include display-flex(center, center);
    margin-bottom: auto;
  }

  .okra-popup__body {
    height: 470px;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
  }
  .close {
    position: absolute;
    right: 26px;
    top: 26px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      path {
        stroke: $dark-grey;
      }
    }
  }
  .success {
    margin-top: auto;
    margin-bottom: 40px;
  }
  &__link {
    margin: 24px 0;
  }
  .link-section {
    border: 1px solid $grey-variant-200;
    padding: 0 16px;
    @include display-flex;
    border-radius: 6px;
    margin: 24px 0;
    height: convertPxToRem(40);

    a {
      @include display-flex;
      border-left: 1px solid $grey-variant-200;
      padding-left: 16px;
      color: $primary;
      font-weight: 500;

      svg {
        margin-left: 8px;

        path {
          stroke: $primary;
        }
      }
    }

    .copy-card {
      color: $dark-grey;
      outline: 1px solid;
    }

    .copy-element {
      font-size: convertPxToRem(14);
      color: $dark-grey;
    }
  }
  .custom-section {
    @include display-flex(flex-start);
    margin-bottom: 40px;
    &__icon {
      width: 48px;
      height: 48px;
      background-color: $deep-purple;
      @include display-flex(center);
      border-radius: 50%;
      margin-right: 16px;
    }
    div:last-child {
      text-align: left;
      p {
        color: $dark-grey;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .exit-section {
    @include display-flex(space-between, center);

    .checkbox {
      width: auto;

      .checkbox__label {
        &__content {
          color: $dark-grey;
        }
      }
    }
  }
}
