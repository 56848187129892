.builder-landing {
  height: 100vh;

  @include display-flex(center, center, column);

  &-content {
    margin-bottom: auto;

    > h4 {
      padding-bottom: 40px;
      text-align: center;
      font-size: 24px;
    }
    &__options {
      @include display-flex(flex-start, center);
      gap: convertPxToRem(32);

      > div {
        border: 1px solid $grey-variant-200;
        height: convertPxToRem(498);
        border-radius: convertPxToRem(16);
        width: convertPxToRem(448);
        padding: convertPxToRem(64);

        @include display-flex(center, center, column);

        h4 {
          margin-bottom: 8px;
          color: $dark-blue-9;
        }
        p {
          margin-top: convertPxToRem(8);
          color: $dark-blue-7;
          text-align: center;
          margin-bottom: convertPxToRem(24);
        }

        .icon {
          margin-bottom: convertPxToRem(40);
        }
      }
    }
  }
}
