.internet-notification {
  .okra-popup__content {
    width: max-content;
    height: auto;
    min-height: auto;
    text-align: center;
    padding: 12px 18px;
    @include respond(phone) {
      width: 100%;
    }
  }
  .icon {
    background-color: $warning;
    border-radius: 4rem;
    width: 4rem;
    height: 4rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 19px;
    width: 16px;
  }

  p {
    color: $dark-grey;
  }
}
