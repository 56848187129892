.api-keys-v2 {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: $grey-900;

    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $grey-variant-200;

    @include respond(tab-port) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__text {
    margin-bottom: 4px;
  }

  .subtitle {
    color: $grey-700;
  }

  .card {
    border-radius: 12px;
    margin: 24px 0;
    padding: 17px 24px;
    box-shadow: 0px 0.5px 0px rgba(68, 83, 104, 0.08),
      0px 0.5px 0px rgba(52, 64, 83, 0.12), 0px 1px 0.5px rgba(34, 44, 59, 0.16);

    @include respond(tab-port) {
      background: $white;
      padding: 16px;
    }
  }

  p.token {
    color: $grey-700;
  }

  .token-card .card-content {
    @include display-flex(space-between, center);
    @include respond(tab-port) {
      background: $white;
      @include display-flex(flex-start, flex-start, column);
    }
  }

  &__card {
    &__header {
      .caption {
        color: $grey-700;
        margin-top: 4px;
      }

      @include display-flex(space-between, center);
      margin-bottom: 16px;

      @include respond(phone) {
        @include display-flex(flex-start, flex-start, column);
        margin-bottom: 24px;
      }

      &__buttons {
        @include display-flex(space-between, center);
        gap: 12px;

        svg {
          path,
          mask {
            fill: white !important;
          }
        }

        .refresh-icon {
          path {
            stroke: white !important;
            fill: transparent !important;
          }
        }

        .button-text {
          margin-top: -3px;
        }

        @include respond(phone) {
          margin-top: 16px;
        }
        @include respond(tab-port) {
          @include display-flex(flex-atart, flex-end, column);
          width: 100%;
        }

        & button {
          @include respond(phone) {
            width: 100%;
            height: 48px !important;
          }

          .button-text {
            font-size: 16px;
            margin-left: 6px;
          }
        }
      }
    }

    .api-keys-banner {
      margin-top: 8px;
      @include display-flex(flex-start, flex-start);
      gap: 18px;
      background: $warning-100;
      border-radius: 12px;
      padding: 18px;

      @include respond(tab-port) {
        padding: 16px;
      }

      & svg {
        width: 24px;
        height: 24px;

        @include respond(tab-port) {
          width: 48px;
        }

        @include respond(small-phone) {
          width: 100px;
        }
      }
    }
  }

  &__table {
    &__column {
      @include display-grid(3, 0);
      @include respond(tab-port) {
        display: block;
        border-top: 1px solid $grey-variant-200;
        padding: 24px 0;
      }

      & p {
        border-top: 1px solid $grey-variant-200;
        padding: 19px 0;

        @include respond(tab-port) {
          border: none;
          padding: 0;
        }

        &:first-child {
          @include respond(tab-port) {
            font-weight: 600;
          }
        }
      }

      &:first-child {
        @include respond(tab-port) {
          display: none;
        }
      }

      &__mobile-label {
        display: none;

        @include respond(tab-port) {
          display: block;
          font-weight: 500;
          font-size: 12px !important;
          line-height: 18px !important;
          padding: 24px 0 8px !important;
        }
      }
    }
  }
}
.copy-element {
  @include respond(tab-port) {
    justify-content: space-between !important;
    width: 100%;
  }
}
.token-key {
  @include display-flex(space-between, center);
  gap: 17px;
  div {
    cursor: pointer;
  }

  @include respond(tab-port) {
    width: 100%;
    margin-top: 9px;
  }
  p {
    font-family: 'JetBrainsMono' !important;
    @include respond(small-phone) {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 236px;
    }
  }
}

.modal {
  .okra-popup {
    &__content {
      border-radius: 4px;
      min-height: 340px;
      @include respond(tab-port) {
        max-height: 364px;
        position: absolute;
        bottom: 0;
        transform: translate(-50%, 0%) scale(1) !important;
        top: unset;

        &:has(.otptextfield__error-message) {
          min-height: 390px;
        }
      }
    }
    &__body {
      padding: 0;
    }
  }

  &.show-keys {
    .okra-popup {
      &__content {
        min-height: 404px;
        @include respond(tab-port) {
          max-height: 504px;
          .modal__footer {
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__header {
    padding: 12px;
    @include display-flex(space-between, center);
    border-bottom: 1px solid $grey-variant-200;

    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      padding-left: 12px;
    }
    svg {
      height: 24px;
      width: 24px; // 16px too small
    }
  }

  &__body {
    padding: 24px;

    .subtitle,
    .token {
      color: $grey-700;
    }

    &.otp {
      overflow: hidden;
      padding: 0;
      margin: 24px;
      @include display-flex(space-between, flex-start, column);

      .otptextfield-container {
        margin-top: 24px;

        .otptextfield-input-container {
          @media (max-width: 360px) {
            * {
              margin: 0;
            }
          }
        }
      }

      .subtitle {
        span {
          color: $grey-900;
        }
      }

      .footnote {
        @include display-flex(flex-start, center);
        color: $grey-700;
        margin-top: 24px;
        p {
          color: $grey-700;
        }

        button {
          margin-left: 16px;
          svg {
            display: none;
          }
        }
      }
    }

    & p {
      font-size: 14px;
      line-height: 22px;
    }

    &__title {
      margin-bottom: 24px;
    }
    &__row {
      padding: 16px 0 0;
      border-top: 1px solid $grey-variant-200;

      & p {
        font-weight: 600;
      }
      span {
        margin: 17px 0 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 388px;
        font-family: 'JetBrainsMono' !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    padding: 12px 24px;
    width: 100%;
    @include display-flex(flex-end, center);
    gap: 12px;
    border-top: 1px solid $grey-variant-200;

    @include respond(tab-port) {
      @include display-flex(space-between, center);
    }
    .button {
      @include respond(tab-port) {
        padding: 12px 39.5px !important;
      }
    }
  }
}

.generate-keys {
  .buttons {
    display: none;
  }

  .okra-popup {
    &__content {
      @include respond(tab-port) {
        max-height: 258px;
        position: relative;
        transform: translate(-50%, -50%) scale(1) !important;
        top: 50%;
        margin-bottom: 40px;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  .okra-popup__footer {
  }

  .modal__footer {
    border: none;
    padding: 0 0 29px 0;
    width: 435px;

    @include respond(tab-port) {
      margin-bottom: 20px;
      width: 88%;
      padding: 12px 0;
      gap: 0;
      justify-content: flex-end;
    }
  }
}
