@import './FundWallet/fund-wallet';

.wallet-subtitle {
  max-width: 700px;
}

.wallet-overview {
  padding: 0 !important;
  margin-bottom: 32px;
  .flex {
    @include display-flex(flex-start, center);
    .caption {
      margin-bottom: 0;
      margin-left: 4px;
    }
  }
  .card-content {
    width: 100%;
    @include display-flex(flex-start, flex-start);

    @include respond(tab-land) {
      display: block;
    }

    & > div {
      width: 50%;
      padding: 32px;

      @include respond(tab-land) {
        width: 100%;
      }
      @include respond(tab-port) {
        padding: 16px 0;
      }
      &:first-child {
        border-right: 1px solid $light-grey;

        @include respond(tab-land) {
          border-right: 0;
          border-bottom: 1px solid $light-grey;
        }
      }
    }
  }

  &__section {
    &--header {
      @include display-flex();
      margin-bottom: 16px;

      .wallet-actions {
        @include display-flex(flex-start);

        & > * {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      &.bigger {
        @include respond(tab-port) {
          flex-direction: column;
          align-items: flex-start;
          .wallet-actions {
            margin-top: 8px;
          }
        }
      }
    }

    &--content {
      @include display-flex(flex-start);

      h4 {
        margin-right: 16px;
      }
    }
  }

  .wallet-balance-items {
    @include display-flex(flex-start, flex-start);

    @include respond(tab-port) {
      display: block;
    }

    .wallet-balance {
      padding-right: 56px;
      margin-right: 56px;
      border-right: 1px solid $light-grey;

      @include respond(tab-port) {
        padding: 0;
        margin: 0 0 15px;
        border: 0;
      }

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }

      &__currency {
        margin-bottom: 8px;
      }
    }
  }

  .wallet-current-plan,
  .autotopup-details {
    margin-top: 24px;
    border-top: 1px solid $light-grey;
    padding-top: 24px;
  }

  .autotopup-details-item {
    @include display-flex();
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.wallet-api-calls {
  .billable-products {
    max-width: 180px;
    @include display-flex(flex-start, flex-start);
    flex-wrap: wrap;
  }
}

.details-card {
  margin-top: 24px;

  &__section {
    margin-bottom: 32px;

    &--header {
      padding-bottom: 16px;
      border-bottom: 1px solid $light-grey;
      margin-bottom: 16px;
    }

    &--content {
      & > div {
        @include display-flex();
        margin-bottom: 16px;
      }
    }
  }

  .cost-breakdown {
    &__total {
      border-top: 1px dashed $light-grey;
      padding-top: 16px;
    }
  }

  .receipt-details {
    .details-card__section--content {
      @include display-flex(space-between, flex-start);

      & > div {
        width: 100%;
        display: block;
        margin-bottom: 0;

        &:first-child {
          margin-right: 50px;
        }
      }
      @include respond(tab-port) {
        flex-direction: column;
      }
    }

    &__item {
      @include display-flex(flex-start);
      margin-bottom: 16px;

      & > * {
        width: 50%;
      }
    }
  }
}

.wallet-invoice-container {
  .table-card {
    margin-bottom: 32px;

    .table-topbar-container .table-topbar-info {
      width: 100%;
      @include display-flex();
      margin: 0;
      margin-right: 8px;

      & > div {
        display: flex;

        .button {
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }

    .table-filter-container {
      margin: 0;
    }
  }
}
