.institutions-screens {
  .header-section {
    @include display-flex(flex-start, center);
    padding-bottom: 11px;
    border-bottom: 1px solid $light-grey;
    gap: 9px;

    .tooltip {
      @include display-flex(center, center);
    }

    .tooltip-content {
      padding: convertPxToRem(8) convertPxToRem(12);
      width: convertPxToRem(276);

      p {
        font-size: convertPxToRem(12);
        color: $grey-800;
      }
    }

    p {
      color: $grey-800;
    }
  }

  .category {
    padding-top: convertPxToRem(16);
    padding-bottom: convertPxToRem(8);
    border-bottom: 1px solid $light-grey;
  }

  label {
    font-size: 14px;
    font-weight: 500 !important;
  }
  .select-container__multiselect {
    width: 320px;
  }
  .default-screen,
  .all-screen {
    .section {
      margin-bottom: 16px;
      padding-bottom: 16px;
      &:not(:last-child) {
        border-bottom: 1px solid $light-grey;
      }
    }

    .search-form {
      > * {
        margin: 16px 0;
        background-color: $grey-variant-50;

        input {
          background-color: $grey-variant-50;
        }
        svg {
          path {
            fill: $dark-grey;
          }
        }
      }
      position: relative;
    }

    &__left {
      height: 100%;
      border-right: 1px solid $light-grey;
      padding: 24px;
    }

    .row {
      @include display-flex;
      margin-bottom: 12px;
      p:nth-child(2) {
        color: black;
      }
    }
    .space-below {
      margin-bottom: 24px;
    }
    .green-pill {
      font-size: 8px;
      background-color: $success;
      color: white !important;
      padding: 0px 5px;
      border-radius: 6px;
      font-weight: 600;
    }

    &__right {
      margin-top: convertPxToRem(21);
      padding-bottom: 100px;

      .filter {
        @include display-flex(flex-start, center);
        gap: convertPxToRem(9);

        .tooltip-content {
          padding: convertPxToRem(8) convertPxToRem(12);
          width: convertPxToRem(276);

          p {
            font-size: convertPxToRem(12);
            color: $grey-800;
          }
        }

        .tooltip {
          @include display-flex;

          p.body3 {
            color: $grey-variant-500;
          }

          svg {
            path {
              stroke: $grey-variant-500;
            }
          }
        }

        .sliders {
          margin-left: auto;

          .popper-options {
            top: 232px;
            left: 811px !important;

            position: fixed;
            background-color: white;
            z-index: 99999;
            width: convertPxToRem(200);
            padding: convertPxToRem(9) 0;
            margin-top: 5px;

            .filter-option {
              @include display-flex(space-between, center);
              border: none;
              padding: convertPxToRem(8) convertPxToRem(16);
              cursor: pointer;

              &:hover {
                background-color: $grey-variant-50;
              }

              &.active {
                background-color: $green-50;
              }

              svg {
                path {
                  fill: #008059;
                }
              }
            }
          }

          &__button {
            @include display-flex(center, center);

            cursor: pointer;
            height: convertPxToRem(32);
            width: convertPxToRem(32);
            border-radius: 999px;
            transition: all 0.3s;

            &:hover {
              background-color: $grey-variant-100;
            }
          }
        }
      }
    }
  }
  .bank-list {
    margin-bottom: 32px;
    margin-top: convertPxToRem(10);

    &__item {
      @include display-flex;
      border-bottom: 1px solid $grey-variant-200;
      padding: convertPxToRem(16) 0;
      transition: all ease-in-out 0.3s;
      cursor: move;

      &:last-child {
        border: none;
      }

      > div:first-child {
        @include display-flex;

        svg {
          margin-right: 16px;

          path {
            stroke: #627490;
          }
        }
      }

      .bank-cover {
        &__logo {
          width: 24px !important;
          height: 24px !important;
          border: 1px solid $grey-variant-100;
          background-size: contain !important;
        }

        &__details {
          margin-left: convertPxToRem(16);

          p {
            color: $grey-900;
          }
        }
      }

      &:hover {
        background-color: $light;
      }
    }

    &-remove {
      cursor: pointer;
      @include display-flex;

      svg {
        width: 16px;
        height: 16px;
        path {
          stroke: $dark-grey;
        }
      }
    }
  }
  .banks-display {
    &__item {
      padding: convertPxToRem(9) 0;
      border-bottom: 1px solid $light-grey;

      .bank-cover {
        &__logo {
          width: 24px !important;
          height: 24px !important;
          border: 1px solid $grey-variant-100;
          background-size: contain !important;
        }

        &__details {
          margin-left: convertPxToRem(16);

          p {
            color: $grey-900;
          }
        }
      }

      .okra-bank-details {
        margin-left: 16px;
        p {
          color: black;
        }
      }
    }
  }

  .banks-search-results {
    position: absolute;
    background: $white;
    border: 1px solid $light-grey;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(57, 83, 123, 0.16);
    border-radius: 6px;
    width: 310px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;

    .bank-cover {
      &__logo {
        width: 24px !important;
        height: 24px !important;
        border: 1px solid $grey-variant-100;
        background-size: contain !important;
      }

      &__details {
        margin-left: convertPxToRem(16);

        p {
          color: $grey-900;
        }
      }
    }

    > div {
      @include display-flex;
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background-color: $light;
      }
    }

    .empty-search {
      padding: 8px 16px;
    }
  }

  .all-screen {
    margin: convertPxToRem(24);

    .banks-search-results {
      width: 310px;
    }
  }
}

.custom-institution {
  .header-section {
    @include display-flex(flex-start, flex-start, column);
  }

  .options {
    @include display-flex(center, center);
    margin-top: convertPxToRem(16);
    gap: convertPxToRem(9);

    .option {
      @include display-flex(center, center);
      height: convertPxToRem(32);
      border: 1px solid $grey-variant-200;
      padding: 0 convertPxToRem(16);
      border-radius: 9999px;
      cursor: pointer;

      &.active {
        background-color: $grey-900;

        p {
          color: $white;
        }
      }
    }
  }
}
