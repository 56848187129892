.quick-link-modal,
.create-app-modal {
  .okra-popup__content {
    width: max-content;
  }

  .okra-popup__header {
    padding-top: convertPxToRem(16);
    padding-bottom: convertPxToRem(16);
    h4 {
      font-size: convertPxToRem(18);
      font-weight: 600;
    }
  }

  .okra-popup__footer {
    padding-top: convertPxToRem(16);
    padding-bottom: convertPxToRem(18);
  }

  h4 {
    margin: 0 12px;
  }
}

.create-app-modal {
  .okra-popup__content {
    width: max-content;
  }

  h5 {
    margin: 0 12px;
  }

  .app-options {
    margin: 32px 12px 70px 12px;

    .info {
      color: $dark-grey;
      margin-top: convertPxToRem(8);
    }

    @include display-flex(flex-start);
    @include respond(tab-port) {
      flex-direction: column;
    }

    > div {
      border: 1px solid $light-grey;
      border-radius: 8px;
      padding: 24px;
      width: 324px;
      cursor: pointer;
      // transition: all 0.3s;
      &.active {
        border: 2px solid #12b75c;
        box-shadow: 0 4px 8px rgba(57, 83, 123, 0.08);
        padding: 23px;
      }
    }

    div:first-child {
      margin-right: 24px;
      @include respond(tab-port) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    @include respond(tab-port) {
      width: 100%;
      > div {
        margin: 0;
      }
    }
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @include display-flex(center);
      svg {
        width: 24px;
        height: 24px;
      }
      margin-bottom: 16px;
      &.layout {
        background-color: #7422c5;
      }
      &.link {
        background-color: #12b75c;
      }
    }
  }
}

.quick-link-modal {
  .okra-popup__content {
    width: 720px !important;
  }

  .free-tag {
    text-transform: uppercase;
    padding: 0 6px;
    height: convertPxToRem(20);
    font-size: 8px;
    font-weight: 600;
    background-color: $success-500;
    color: white;
    border-radius: 4px;
  }

  .checkbox-container.full {
    label {
      width: 100%;
    }

    .checkbox__label {
      opacity: 1;
      width: 100%;

      .row {
        width: 100%;
        @include display-flex(space-between, center);

        span {
          @include display-flex(center, center);
        }
      }
    }
  }

  .okra-popup__content {
    width: max-content;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
    @include respond(phone) {
      width: 100%;
      height: 100%;
    }
    .okra-popup__body {
      padding: 0;
      display: flex;
      @include respond(phone) {
        flex-direction: column;
      }
      height: 100%;
      @include respond(phone) {
        height: auto;
      }
      > div:first-child {
        height: 100%;
        @include respond(phone) {
          width: 100%;
        }

        p.medium {
          padding-top: convertPxToRem(24);
          padding-bottom: convertPxToRem(28);
          padding-left: convertPxToRem(28);
          padding-right: convertPxToRem(28);
        }
      }
      > div:last-child {
        height: 100%;
        padding: 24px;
        border-left: 1px solid $light-grey;
        width: 100%;

        @include respond(phone) {
          width: 100%;
          border-left: none;
        }
      }
      div.options {
        .checkbox-container {
          height: convertPxToRem(40);
          padding-left: convertPxToRem(24);
          padding-right: convertPxToRem(16);

          @include display-flex(center, center);

          &:hover {
            background-color: $light;
            cursor: pointer;
          }
        }
      }
    }
    .file-uploader,
    .input-container,
    .select-container {
      margin-bottom: 32px;
    }
  }
}

.app-inner {
  padding: 0 !important;

  &__section {
    display: flex;

    &-left {
      width: 60%;
      padding-top: 24px;
      padding-right: 24px;
      border-right: 1px solid $light-grey;
      flex: 1;
    }

    .app-list {
      display: flex;
      min-width: 350px;
      justify-content: space-between;
      overflow: auto;

      > h5 {
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 1px solid $light-grey;
      }

      > div {
        flex-direction: column;
        flex: 1;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        p {
          &.body2 {
            height: 40px;
            margin: 0;
            line-height: 40px;
            font-size: 14px;
          }
        }

        img {
          width: 50px;
          margin-top: 12px;
          margin-bottom: 5px;
          height: 5%;
        }
      }
    }

    &-right {
      padding-left: 24px;
      width: 40%;
      > div {
        margin: 20px 0;
      }
    }

    @include respond(tab-land) {
      flex-direction: column;

      &-right {
        width: 400px;
        padding: 12px 0;
      }

      &-left {
        border-bottom: 1px solid $light-grey;
        border-right: none;
        padding: 12px 0;
      }
    }
  }
  .products {
    padding: 24px;

    .products-container {
      padding: 32px;
      border: 1px solid $light-grey;
      margin-top: 24px;
      border-radius: 10px;

      .product {
        padding: 32px 24px;
        border: 1px solid $light-grey;
        width: 48%;
        border-radius: 10px;
        margin-top: 20px;
        p.medium {
          margin: 6px 0;
        }

        @include respond(tab-port) {
          width: 100%;
        }
      }
      .products-body {
        @include display-flex;
        @include respond(tab-port) {
          flex-direction: column;
        }
      }
    }
  }
}

.integration {
  padding-top: 24px;

  > .textfield-control {
    padding-bottom: 24px;
    min-width: 300px;
    max-width: 500px;
  }
  &__section {
    > .select-container {
      margin-bottom: 24px;
      min-width: 300px;
      max-width: 500px;
    }
  }
}

.code-block-top-bar {
  display: flex;
  justify-content: space-between;
  height: 48px;
  background-color: $grey-800;
  border: 1px solid $grey-700;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .top-bar-select {
    background-color: $grey-800;
    color: $grey-100;
    width: auto;
    max-width: 200px;
    margin-top: 8px;
    margin-left: 16px;
    margin-bottom: 8px;
    border: none !important;
    outline: none;
  }

  .copy-code {
    background-color: $grey-700;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 16px;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }
  }
}

.code-block {
  height: 726px;
  margin-bottom: 120px;
  overflow: auto;
  font-size: 14px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  * {
    font-family: 'IBM Plex Mono', monospace !important;
  }

  .csNcpH {
    padding: 0.5rem;
  }
  .sc-gsnTZi {
    border-radius: 0 !important;
  }
  > span {
    border-radius: 0 !important;
  }
  .textfield-container {
    background-color: $grey-800 !important;
  }

  .select-container {
    width: 150px;
  }

  .textfield-container {
    border: none !important;
  }
}

.tracking-modal {
  .okra-popup__body {
    .input-container,
    .select-container {
      margin-top: 18px;
    }
  }
  a {
    color: $primary;
  }
}

.apps {
  &-header {
    margin-bottom: 24px;

    h4 {
      margin-bottom: 8px;
    }

    p {
      color: $grey-700;
    }
  }
}

.create-app-drawer {
  .drawer-body {
    padding: 24px;
  }

  .drawer-main .drawer-content .drawer-header {
    padding: 12px 24px;
  }

  .header {
    @include display-flex;
    gap: 16px;

    .button {
      padding: 0 !important;
      margin-left: -4px;
    }
  }

  &__initial {
    p.section-title {
      margin-bottom: 24px;
    }

    .app-options {
      .app-option {
        margin-bottom: 16px;
        border: 1px solid $grey-variant-200;
        border-radius: 16px;
        padding: 16px;
      }

      .list-item--active {
        border-color: $green-50;
      }
    }
  }

  &__quick-links-home {
    .payment-options {
      margin-top: 24px;

      span.label {
        color: $grey-800;
      }

      .checkbox-container {
        margin-top: 8px;
        height: 32px;
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      margin: 24px 0;
      background-color: $grey-variant-200;
    }
  }

  &__quicklinks-form {
    .forminput-control {
      margin-bottom: 24px;
    }

    .checkbox-container {
      margin-top: 8px;
    }

    .md-button {
      padding: 0 !important;
      margin: 24px 0;
      height: auto !important;
      border-radius: 0;

      &:hover {
        background-color: unset !important;
      }

      &:active {
        background-color: unset !important;
      }
    }
  }
}
