.builder-products {
  .product-section-info {
    margin: 24px;
    border-bottom: 1px solid $light-grey;

    p.semibold,
    p.medium {
      margin-bottom: 8px;
      color: $grey-900;
      @include display-flex(flex-start, center);
      gap: 16px;
    }

    p.subtitle {
      margin-bottom: convertPxToRem(24);
      font-size: 14px;
      color: $grey-700;
      span {
        cursor: pointer;
        color: $primary;
      }
    }

    .button {
      margin-bottom: 24px;

      .button-text {
        font-weight: 600;
      }
    }
  }

  .sub-info {
    @include display-flex(flex-start, flex-start);
    p {
      color: $dark-grey;
      font-size: 14px;
      line-height: 22px;
    }
    svg {
      margin-top: -12px;
      margin-right: 12px;
    }
    &.colored {
      @include display-flex(center, flex-start);

      p {
        color: black;
      }

      .colored__icon {
        width: 20px;
        height: 20px;
        margin-top: convertPxToRem(8);
        margin-right: convertPxToRem(10);

        svg {
          width: 20px;
          height: 20px;
        }
      }

      background-color: $light-yellow;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
    }
  }
  .extra-screen {
    position: absolute;
  }

  .settings {
    .textfield-control {
      margin-bottom: convertPxToRem(24);
    }
  }
  .product-section {
    margin: 0 24px;

    .select-container {
      margin-top: 16px;
      label: {
        font-size: 14px;
      }
    }

    > div {
      margin-bottom: 24px;
    }

    .mt-8 {
      margin-top: convertPxToRem(8);
    }

    .action-section {
      @include display-flex(space-between, center);
      //margin-top: 16px;

      &.disabled {
        p.mt-8 {
          color: $grey-200;
        }

        p.medium {
          color: $grey-300;
        }
      }

      .tooltip-container {
        width: 100%;
      }

      p.mt-8 {
        margin-top: convertPxToRem(8);
      }

      p.body3 {
        color: $grey-700;
      }

      .toggle-container {
        width: auto;
        margin-left: auto;
      }
    }

    .file-picker {
      margin-top: convertPxToRem(24);

      &__label {
        margin-bottom: convertPxToRem(4);
        color: $dark-blue;
      }

      .file-uploader-dropzone {
        @include display-flex;
        height: 40px;
        width: 312px;
        flex-direction: row;
        min-height: 40px !important;
        background-color: white;
        padding-right: 10px;
        padding-left: 16px;
        //border-style: dashed;
        //border-color: $grey-variant-200;
        //padding: convertPxToRem(9);
        //
        .textfield-container {
          width: 312px;
        }
        > p {
          font-size: convertPxToRem(14);
          color: $grey-400;
        }

        .file-uploader-dropzone__icon {
          order: 2;

          > svg {
            height: 16px !important;
          }
        }
      }

      .footnote {
        color: $critical-500 !important;
      }
    }

    .upload-instruction {
      padding: 24px;
      border: 1px solid $grey-variant-200;
      border-radius: 12px;
      height: 314px;
      width: 312px;

      &__title {
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid $grey-variant-200;
      }

      &__icons {
        @include display-flex(flex-start);
        height: 16px;
        align-items: center;
        padding-bottom: 24px;
        margin-bottom: 16px;
        border-bottom: 1px solid $grey-variant-200;

        > svg:first-child {
          height: 16px;
          margin-right: 24px;
        }

        > svg:last-child {
          height: 24px;
          margin-left: 24px;
        }
      }

      &__guide-list {
        padding-left: 12px;
        > li {
          font-size: 14px;
          color: $grey_700;
          list-style-type: disc;
          display: list-item;
          line-height: 22px;
          padding-left: 4px;
          padding-bottom: 4px;

          &::marker {
            font-size: 8px;
            margin-bottom: 2px;
            text-indent: 2px !important;
          }
        }
      }
    }

    .transaction-history {
      padding-top: convertPxToRem(24);
      margin-top: 24px;
      border-top: 1px solid $light-grey;

      &__options {
        margin-top: convertPxToRem(8);

        .radio {
          width: 100%;
          margin-bottom: convertPxToRem(18);

          label {
            width: inherit;
          }

          &__label {
            width: inherit;
            color: $dark-blue;
          }
        }
      }
    }

    .account-balance {
      padding-top: convertPxToRem(24);
      //margin-top: 24px;
      border-top: 1px solid $light-grey;

      .tooltip-content {
        padding: convertPxToRem(8) convertPxToRem(12);
      }
    }

    p.subinfo {
      font-size: 14px;
      color: $dark-grey;
      margin-top: 11px;
      margin-bottom: 8px;
    }

    p.action {
      @include display-flex(flex-start);
      color: $grey;
      svg {
        margin-left: 12px;
        path {
          stroke: $grey;
        }
      }
      &.active {
        color: $primary;
        svg {
          path {
            stroke: $primary;
          }
        }
      }
    }

    .view-options {
      @include display-flex();
      &__option {
        flex: 1;
        transition: all 0.2s;
        // height: 136px;
        // width: 144px;
        cursor: pointer;
        padding: 16px;
        border: 1px solid $light-grey;
        border-radius: 8px;
        &:first-child {
          margin-right: 24px;
        }
        &.active {
          border: 2px solid $success;
          box-sizing: border-box;
          box-shadow: 0 4px 8px rgba(57, 83, 123, 0.08);
          border-radius: 8px;
        }
      }
    }

    .list-bars {
      margin-top: 28px;
      .list-bar {
        @include display-flex(flex-start);
        margin-bottom: 8px;
        div:first-child {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
          &.green {
            background-color: $light-green;
          }
          &.purple {
            background-color: $light-purple;
          }
        }
        div:last-child {
          width: 59px;
          height: 4px;
          background-color: $light-ash;
          border-radius: 100px;
        }
        .second {
          width: 39px !important;
        }
      }
    }
    .grid-bars {
      @include display-grid(2, 14px);
      margin-top: 28px;

      .grid-bar {
        width: 100%;
        height: 24px;
        border-radius: 4px;
        padding: 10px;
        @include display-flex(center);
        div {
          width: 29px;
          height: 4px;
        }
        &.green {
          background-color: $light-green;
          div {
            background-color: $primary;
          }
        }
        &.purple {
          background-color: $light-purple;
          div {
            background-color: $deep-purple;
          }
        }
        &.grey {
          background-color: $ash;
          div {
            background-color: $light-ash;
          }
        }
      }
    }
  }
  .tooltip-inner {
    width: 200px;
    color: black !important;
  }

  .product-form {
    margin: 24px;
    border-top: 1px solid $grey-variant-200;

    .textfield-control {
      padding-top: 24px;
    }

    .checkbox {
      margin-top: 8px;

      label {
        align-items: flex-start;
      }
    }
  }
}

.coming-soon {
  text-transform: uppercase;
  padding: 4px 6px;
  font-size: 8px;
  font-weight: 600;
  border-radius: 4px;
  color: white;
  background-color: $warning;
  &.left {
    margin-left: 8px;
  }
  &.primary {
    background-color: $primary;
  }
}

.account-types {
  &__options {
    margin-top: convertPxToRem(8);

    .radio {
      width: 100%;
      margin-bottom: convertPxToRem(18);

      label {
        width: inherit;
      }

      &__label {
        width: inherit;
        color: $dark-blue;
      }
    }
  }
}

.institution-options {
  &__item {
    margin-bottom: convertPxToRem(24);

    label {
      align-items: flex-start !important;
    }

    p.medium.body3 {
      margin-bottom: convertPxToRem(8);

      .status-chip {
        margin-left: convertPxToRem(8);
      }
    }
  }
}

.info-callout {
  background-color: $info-100;
  padding: convertPxToRem(16);
  border-radius: convertPxToRem(8);
  margin: 24px;
  @include display-flex(center, flex-start);
  gap: convertPxToRem(10);

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    color: $grey-800;
  }
}

.payments-tooltip {
  width: 100%;

  .disabled {
    margin-top: 0 !important;
    opacity: 0.5;
  }
}
