@import './NewUserView/RequestAccess/request-access';

.payments-overview {
  position: relative;

  .card-header {
    font-weight: 600;
    font-size: 1.8rem;
    font-family: $primary-font;
  }

  .card-description {
    font-size: 1.6rem;
  }

  &__new {
    padding: 40px;
  }

  &-hero {
    height: 100vh;
    max-height: 726px;

    @include respond(tab-land) {
      height: auto;
      max-height: none;
    }

    &__text {
      @include display-flex(space-between, center, column);
      height: 100%;
      max-width: 465px;

      @include respond(tab-land) {
        max-width: none;
      }

      & > div:first-child {
        margin-bottom: 35px;
      }
    }

    &__tag {
      @include display-flex(flex-start, center);
      color: $success;

      svg {
        margin-right: 10px;
      }
    }

    &__title {
      margin: 16px 0;
    }

    &__brands {
      @include display-flex(flex-start, center);
      margin-top: 24px;

      & > span {
        display: flex;
        margin-left: -10px;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__actions {
      @include display-flex(flex-start, center);
      margin-top: 24px;

      .button {
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__image {
      position: absolute;
      top: 0;
      right: -32px;
      max-width: 500px;

      @include respond(tab-land) {
        position: relative;
        top: unset;
        right: unset;
      }

      @include respond(tab-port) {
        display: none;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &-features {
    @include display-grid(3, 32px);
    margin-top: 128px;

    @include respond(tab-land) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 50px;
    }

    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @include display-flex(center, center);

      &.icon-green {
        background: #f0f8e8;
      }

      &.icon-purple {
        background: #f0e8f8;
      }

      &.icon-blue {
        background: #e8f0f8;
      }
    }

    &__title {
      margin: 24px 0 8px;
    }
  }

  &-footer {
    background: #f0f8e8;
    border-radius: 16px;
    padding: 64px;
    margin-top: 160px;
    position: relative;

    @include respond(tab-land) {
      margin-top: 50px;
    }

    @include respond(tab-port) {
      padding: 24px;
    }

    &__text {
      max-width: 344px;

      h4 {
        margin-bottom: 8px;
      }

      p {
        margin-bottom: 24px;
      }
    }

    &__image {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;

      @include respond(tab-land) {
        display: none;
      }
    }
  }

  .quick-links {
    padding: 0;
    margin-bottom: 32px;

    &__close {
      display: flex;
      cursor: pointer;
      width: 15px;

      svg {
        path {
          stroke: $dark-grey;
        }
      }
    }

    .card-header {
      @include display-flex();
      border-bottom: 1px solid $light-grey;
      padding: 18px 32px;
      margin-bottom: 0;
      color: $black;
    }

    .card-content {
      padding: 24px;
      @include display-grid(3, 24px);

      @include respond(tab-land) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond(tab-port) {
        grid-template-columns: repeat(1, 1fr);
      }

      .action-card {
        margin: 0;
        width: 100% !important;

        .card-header {
          border-bottom: 0;
          padding: 0;
          margin-bottom: 4px;
        }
      }
    }
  }
}
