.inner-trans-container {
  & > .row {
    h4 {
      margin-top: 0;
    }

    .datepicker-container {
      width: auto;

      .datepicker-render-container {
        height: 32px;
      }
    }
  }
  p.desc {
    word-break: break-word;
  }
  .trans-info {
    margin: 24px 0 32px 0;
    padding: 0;
    .single-trans-desc {
      p.medium {
        padding-bottom: 16px;
        border-bottom: 1px solid $light-grey;
        margin-bottom: 16px;
      }
      > div {
        flex: 1;
      }
      > div:first-child {
        border-right: 1px solid $light-grey;
        padding: 32px;
      }
      .infocard-table__row:last-child {
        margin-bottom: 0;
      }
      &__txns {
        padding: 32px;
        .row {
          p.caption {
            margin-bottom: 4px;
          }
          & > div {
            @include display-flex(flex-start);
            flex: 1;
          }
          & > div:first-child {
            border-right: 1px solid $light-grey;
            padding-right: 40px;
            margin-right: 40px;
          }
          svg {
            margin-right: 16px;
          }
          @include respond(tab-port) {
            & > div:first-child {
              border-right: none;
              padding-right: 0;
              margin-right: 0;
              margin-bottom: 24px;
            }
          }
        }
      }
      @include respond(tab-land) {
        flex-direction: column;
        .infocard-table {
          width: 100%;
          border-right: none !important;
        }
        .single-trans-desc__txns {
          width: 100%;
        }
      }
      @include respond(phone) {
        .infocard-table {
          padding: 12px !important;
        }
        .single-trans-desc__txns {
          padding: 12px;
        }
      }
    }
  }
  .debit {
    color: $critical;
    display: flex;
    &::before {
      content: '-';
    }
  }
  .credit {
    color: $success;
    display: flex;
    &::before {
      content: '+';
      display: inline;
    }
  }
  .trans-pill {
    width: 100px;
  }
  // .desc {
  // max-width: 300px;
  // }
}
