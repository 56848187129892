.projects {
  .row {
    @include display-flex(flex-start);

    .status-chip {
      margin-left: 12px;
    }
  }
  .tr-action {
    svg {
      path {
        fill: $primary;
      }
      margin-left: 10px;
    }
  }
  &-create-container {
    padding: 24px;
    > div {
      margin-top: 24px;
    }
    .file-uploader {
      width: 100%;
    }
    p.caption {
      margin-bottom: 8px;
    }
    .toggle {
      margin: 24px 0;
      cursor: pointer;
      background-color: $light;
      color: $dark-grey;
      width: fit-content;
      padding: 8px 16px;
      border-radius: 6px;
      svg {
        margin-left: 10px;
        path {
          fill: $dark-grey;
        }
      }
    }
    .section {
      p {
        font-weight: 500;
        font-size: 14px;
        margin-top: 40px;
        margin-bottom: 18px;
      }
    }
    label.subheader {
      display: block;
      margin-bottom: 6px;
    }
  }
  &-inner {
    &-header {
      display: flex;
      justify-content: space-between;
      &__info {
        display: flex;
        align-items: center;

        .pill {
          margin-left: 8px !important;
          padding: 3px 8px;
        }

        h4 {
          margin: 0;
        }
      }
      &__buttons {
        display: flex;
        align-items: center;
        button {
          margin-left: 20px;
        }
        @include respond(tab-port) {
          button {
            //small button
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.01px;
            height: 32px;
          }
        }
      }
      @include respond(tab-port) {
        flex-direction: column;
        &__buttons {
          margin-top: 20px;
          button:first-child {
            margin-left: 0px;
          }
        }
      }
    }
    &-card {
      margin-top: 24px;
      p.medium:first-child {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $light-grey;
      }
      .content {
        display: grid;
        grid-template-columns: 30rem 1fr;
        p:nth-child(odd) {
          color: $dark-grey;
        }
        @include respond(phone) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
