.builder-branding {
  // width: 100%;
  // height: 100%;
  // @include display-flex(space-between, flex-start);
  // position: relative;
  &-panel {
    position: absolute;
    width: 360px;
    height: 100%;
    overflow-y: auto;
    box-shadow: $popper-shadow;
    background-color: white;

    p.title {
      padding: 11px 24px;
      border-bottom: 1px solid $light-grey;
    }
    .content {
      padding: 24px;
      p {
        font-size: 14px;
      }
      > * {
        margin-bottom: 32px;
      }
    }
  }

  .builder-wigdet-container {
    margin-left: 360px;
  }
}
