.sidenav-container.closed + .dashboard-layout-content {
  left: 64px;
  width: calc(100% - (64px)) !important;
  transition: left 0.2s ease-in-out 0.2s, width 0.2s linear 0.4s;
  @include respond(phone) {
    width: 100% !important;
  }
}

.dashboard-layout {
  &-body {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }

  &-container {
    position: relative;
    left: 240px;
    width: calc(100% - 240px) !important;
    height: 100%;
    overflow: hidden;

    @include respond(tab-land) {
      margin: 0;
      width: 100% !important;
      position: unset;
    }

    &.full-layout {
      .dashboard-layout-content {
        padding: unset;

        @include respond(tab-port) {
          padding: unset !important;
        }
      }

      .dashboard-page {
        max-width: 100% !important;
        margin: unset !important;
      }

      .page-loader {
        padding: 32px;
      }
    }
  }

  .dashboard-layout-content {
    padding: 0 32px;
    background-color: $white;
    height: calc(100vh - 56px);
    overflow: auto;

    @include respond(tab-land) {
      padding: 0 12px;
    }

    .dashboard-page {
      max-width: 1200px;
      margin: 10px auto;

      @include respond(tab-land) {
        padding: 0 60px;
      }

      @include respond(tab-port) {
        padding: 0 42px;
      }
      @include respond(phone) {
        padding: 0;
      }
    }
  }

  .sidenav-container {
    position: fixed;
    border-right: 1px solid $grey-variant-200;

    @media only screen and (min-width: 37.5em) {
      height: 100vh;
    }
  }
}

.support-modal__new {
  .okra-popup__body {
    p {
      font-weight: 400;
    }
  }
}
