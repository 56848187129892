.identity-preview {
  width: 120px;
  p {
    @include display-flex();
    flex: 1;
    font-size: 12px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.identity-card-container {
  margin-top: 24px;
  .identity-card {
    @include display-flex(space-between, flex-start);
    @include respond(tab-land) {
      flex-direction: column;
      align-items: center;
      * {
        width: 100%;
      }
      .infocard-table__row {
        width: 100%;
      }
      .pill {
        width: max-content;
      }
    }
    &__photo {
      width: 200px;
      @include display-flex(center);
      margin-right: 32px;
      .blank-img {
        width: 100%;
        border-radius: 12px;
        background-color: $ash;
        @include display-flex(center);
        svg {
          margin-top: 70px;
          width: 152px;
        }
      }
    }
    &__desc {
      flex: 1;
      p.medium {
        padding-bottom: 16px;
        border-bottom: 1px solid $light-grey;
        margin-bottom: 16px;
      }
      @include respond(tab-land) {
        margin-top: 32px;
      }
    }
  }
}
