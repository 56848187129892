$filter-height: 64px; // table filter height
$heading-total: 88px; // margin above heading, and heading height

.pointer {
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
.main-sub-info {
  @include display-flex(flex-start, flex-start);
  background-color: $status-tint;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 32px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    path {
      fill: $status-info;
    }
  }
  p {
    color: $dark-blue;
  }
}

.webhook {
  .table-date {
    color: $dark-grey;
  }
  h3 {
    margin-bottom: 8px;
  }
  .card-container {
    display: flex;

    @include respond(tab-land) {
      flex-direction: column;
    }

    @include respond(phone) {
      flex-direction: column;
    }

    &--production,
    &--sandbox {
      width: 100%;
      margin-bottom: 24px;
      .card-content {
        display: flex;
        flex-direction: column;
      }

      .input {
        margin-bottom: 24px;
      }

      margin-right: 32px;

      h4 {
        margin-bottom: 8px;
        text-transform: capitalize;
      }

      p {
        color: $dark-blue;
        font-size: 14px;
        margin-bottom: 24px;
      }

      button {
        font-size: 14px;
        font-weight: 600;
        color: $white;
        background-color: $okra-green;
        padding: 8px 16px;
        border-radius: 6px;
        max-width: 125px;
        align-self: end;
        white-space: nowrap;

        &:disabled {
          background-color: #ade0d1;
          pointer-events: auto;
          cursor: not-allowed;
        }
      }
    }
  }

  .log-details-card {
    overflow: auto;
    margin-left: 16px;
    position: sticky;
    top: 64px;
    height: 100%;
    max-height: calc(
      100vh - #{$topbar-height} - #{$filter-height} - #{$heading-total}
    );

    .response-body,
    .request-body {
      h4 {
        margin-bottom: 16px;
        font-size: 100%;
        @include display-flex(space-between, center);
      }
      pre {
        border-radius: 8px;
        max-height: 420px;
      }
    }

    .response-body {
      margin-bottom: 24px;
    }
  }

  .details {
    border-top: 1px solid #e8ebec;
    padding: 24px 0px;

    .detail {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 12px;
      font-size: 14px;
      .type {
        color: $dark-blue;
      }
      @include respond(tab-port) {
        grid-template-columns: 1fr;
      }
    }
  }

  .actions {
    display: flex;
    margin-bottom: 18px;

    .view {
      margin-right: 16px;
    }
  }

  .log-details-header {
    margin-bottom: 8px;

    .bold {
      font-size: 18px;
      font-weight: 600;
    }

    h4 {
      display: flex;
      align-items: center;
    }

    .active-url {
      white-space: nowrap;
      max-width: 500px;
      @include ellipsis();
    }
    .response {
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 1px 4px;
      margin-left: 12px;
      font-weight: 500;
      font-size: 12px;
      color: $white;
      background-color: red;
      height: 18px;
    }

    .success {
      background-color: green;
    }

    .failed {
      background-color: red;
    }
    &.split {
      @include display-flex;
      .copy-element {
        svg {
          margin-left: 2px;
        }
        font-size: 16px;
        margin-right: 2px;
      }
    }
  }

  .select-log-container {
    display: flex;
    justify-content: center;

    .select-log {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 215px 0px;
      max-width: 300px;
      position: relative;

      .caption {
        font-weight: 600;
        margin-bottom: 8px;
      }

      .text {
        color: $dark-blue;
        text-align: center;
      }

      svg {
        margin-bottom: 32px;
      }
    }
  }

  .log-detail {
    display: flex;
    align-items: center;

    .response {
      border-radius: 6px;
      padding: 1px 4px;
      margin-right: 16px;
      font-weight: 500;
      font-size: 12px;
      color: $white;
      background-color: red;
    }

    .url {
      white-space: nowrap;
      // max-width: 300px;
      width: 80%;
      @include ellipsis();
    }

    .request-type {
      margin-right: 10px;
    }

    .date {
      color: $dark-blue;
      margin-left: 32px;
    }

    .success {
      background-color: green;
    }

    .failed {
      background-color: red;
    }
  }

  .filters {
    @include display-flex;
    padding: 16px 0;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 10;

    .refresh-button {
      margin: 0 0.5rem;
    }

    .search {
      width: 460px;
      margin-right: 16px;
      .textfield-container {
        height: 32px;
        width: 100%;
      }
    }

    .today-button {
      border-radius: 6px 0px 0px 6px;
      height: 40px;
      font-weight: 500;
    }

    .datepicker-container {
      margin-right: 8px;

      .textfield-container {
        border-radius: 0px 6px 6px 0px;
        height: 32px;
      }
    }

    .export-button,
    .tertiary-button {
      margin-left: 8px;
      svg {
        path {
          fill: $dark-grey;
        }
      }
      // &.today-button {
      //  height: 40px;
      // }
      // height: 42px;
      // padding: 0 16px;
      // font-size: 16px;
      // line-height: 26px;
      // font-weight: 600;
      // letter-spacing: -0.01px;
    }
    .action-buttons {
      display: flex;
    }
    @include respond(tab-land) {
      flex-direction: column;
      .action-buttons {
        margin-top: 18px;
      }
    }
    @include respond(tab-port) {
      display: none;
    }
  }

  .container {
    @include display-flex(space-between, stretch);

    @include respond(tab-land) {
      display: grid;
      grid-column-gap: 8px;
      grid-template-columns: 1fr;
      .log-details-card {
        margin: 24px 0 0;
        position: relative;
        top: unset;
        height: auto;
        overflow: unset;
      }
    }

    & > * {
      width: 50%;

      @include respond(tab-land) {
        width: auto;
      }
    }
  }

  .empty-logs {
    .card-content {
      display: flex;
      justify-content: center;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 135px 0px;
      max-width: 352px;

      p {
        font-size: 14px;
      }

      svg {
        margin-bottom: 32px;
      }

      .caption {
        font-weight: 600;
        margin-bottom: 8px;
      }

      .text {
        color: $dark-blue;
        text-align: center;
      }
    }
  }

  .status-button-container {
    .status-popper {
      font-size: 14px;
      padding: 16px;

      .status {
        font-weight: 500;
        padding: 8px 0px;
        margin-top: 8px;
      }

      .option {
        display: flex;
        padding: 16px 0px;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;

        .clear,
        .apply {
          font-weight: 500;
        }

        .clear {
          margin-right: 16px;
        }
      }

      .checkbox-container {
        width: auto;
        margin-right: 16px;
      }
    }
  }
  .event-thread__event {
    font-weight: 500;
  }
  .event-thread__border::after {
    z-index: 1;
  }
}
