.fund-wallet {
  .okra-popup__content {
    min-height: auto;
  }
  .row {
    @include display-flex();
  }
  .space-beside {
    margin-right: 16px;
  }
  .space-below {
    margin-bottom: 24px;
  }
  .space-above {
    margin-top: 24px;
  }
  .error-msg {
    display: block;
    margin-top: -22px;
    margin-bottom: 24px;
    color: $critical;
  }

  &__inputs {
    .input-container {
      margin-bottom: 30px;

      .bold {
        font-weight: 600;
      }
    }

    .payments-method {
      margin-bottom: 8px;
    }

    p.primary-link {
      display: inline-block;
    }
  }

  .currency-field {
    margin-bottom: 0px;
  }

  .relative {
    margin-bottom: 16px;
  }
  .topup-summary-section {
    border-bottom: 1px solid $light-grey;

    & > div {
      @include display-flex();
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__amount {
      margin: 24px 0;
    }

    &__total {
      border-top: 1px solid $light-grey;
      padding: 8px 0;
      margin-bottom: 0 !important;
    }

    &__channel {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .payment-info {
      @include display-flex(flex-start);
      margin-top: 10px;

      &__icon {
        @include full-circle(32px);
        @include display-flex(center);
        border: 1px solid $light-grey;
        box-shadow: 0px 4px 8px rgba(57, 83, 123, 0.08);
        margin-right: 8px;
      }
    }
  }
}
