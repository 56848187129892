.recharts-responsive-container {
  text,
  tspan {
    font-size: 12px;
    font-weight: 600;
    color: $dark-grey;
  }
}

.line-chart-tooltip {
  box-shadow: $popper-shadow;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #25d1a2;
  color: #737a91;
  min-width: 170px;
  .tooltip-title {
    background: #f6f7f9;
    padding: 8px;
  }
  .tooltip-value {
    padding: 8px 8px 4px 8px;
  }
  p {
    margin: 0;
    font-size: 12px;
  }
}

.okra-circlechart {
  &-content {
    @include display-flex(flex-start);
    @include respond(phone) {
      flex-direction: column;
    }
  }
  &-title {
    margin-bottom: 24px;
  }
  p.caption {
    color: $dark-grey;
  }
  &-info {
    margin-left: 80px;
    @include respond(tab-port) {
      margin-left: 10%;
    }
    @include respond(phone) {
      margin-top: 24px;
    }
    > div:first-child {
      margin-bottom: 32px;
    }
  }
}
