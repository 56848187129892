.home-card.products {
  .home-card-header .row {
    padding: 16px 0; //35 is too much
    .stat {
      display: flex;
      padding-right: 80px;
      margin-left: 0;
      &:not(:last-child) {
        border-right: 1px solid $light-grey;
        margin-right: 80px;
      }
      svg {
        margin-right: 16px;
      }
    }
  }
}
