.team {
  .display-info {
    display: flex;
    .avatar-container {
      margin-right: 8px;
    }
    .action-text {
      line-height: 16px;
    }
  }
  .team-table-actions {
    @include display-flex();
    .primary-text-button {
      margin-left: 12px;
    }
  }
  &-invited {
    .avatar-container {
      background-color: $light-grey;
      color: $dark-grey;
    }
  }
  &-inner {
    .breadcrumbs-container {
      margin-bottom: 24px;
    }
    .bare {
      padding: 0;
    }
    .footer {
      padding: 16px;
      display: flex;
      flex-direction: row-reverse;
    }
    .row {
      @include display-flex('', flex-start);
      .hide-on-big {
        display: none;
      }
      @include respond(tab-port) {
        flex-direction: column;
        .hide-on-big {
          display: block;
          margin-bottom: 32px;
        }
      }
      &.top {
        padding: 16px;
        border-bottom: 1px solid $light-grey;
        // margin-bottom: 32px;
        > * {
          flex: 1;
        }
        @include respond(tab-port) {
          p:last-child {
            display: none;
          }
        }
      }
      &.line {
        > * {
          flex: 1;
          padding: 32px;
          width: 100%;
          @include respond(phone) {
            padding: 16px;
          }
        }
        border-bottom: 1px solid $light-grey;
        div.left {
          > div {
            margin-bottom: 32px;
          }
          &-roles {
            margin-top: 32px + 8px;

            p.medium {
              margin-bottom: 16px;
            }
            div.radio {
              margin-bottom: 16px;
            }
            @include respond(tab-port) {
              margin-top: 32px + 32px;
            }
          }
        }
        div.right {
          border-left: 1px solid $light-grey;
          @include respond(tab-port) {
            border-left: none;
          }
          .checkbox-container {
            margin-bottom: 12px;
          }
        }
        .split {
          @include display-flex();
          div:first-child {
            margin-right: 16px;
          }
          @include respond(tab-port) {
            flex-direction: column;
            div:first-child {
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
