$topbar-height: 48px;

.topbar-container {
  @include display-flex(flex-end, center);
  height: $topbar-height;
  background: $white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  border-bottom: 1px solid $grey-variant-200;
  z-index: 999999;

  @include respond(phone) {
    height: $topbar-height;
    @include display-flex(space-between, center);
  }

  .topbar-brand,
  .topbar-actions {
    @include display-flex(flex-start);
  }

  .topbar-brand {
    @include respond(phone) {
      display: none;
    }

    &__logo {
      cursor: pointer;
      @include display-flex(flex-start, flex-start);
      width: 40px;
      margin-right: 8px;
    }

    &__number {
      font-size: 1rem;
      line-height: 17px;
    }
  }

  .topbar-actions {
    height: 100%;

    & > div {
      @include display-flex(flex-start);
    }

    &__mode-toggle {
      height: 100%;
      width: 100%;
      margin-left: 16px;
      align-items: center;

      .popper-trigger {
        cursor: pointer;
        height: 100%;
        @include display-flex(center);
        padding: 0 16px;
        transition: all ease-in-out 0.3s;
        border-left: 1px solid $grey-variant-200;

        &:hover {
          background-color: $grey-variant-50;
        }
      }

      .popper-options {
        margin-right: 20px;
        overflow: hidden;
        width: 100%;
      }

      .env-toggle {
        @include display-flex;

        &_state {
          padding: 5px 12px;
          border-radius: 99999px;
          margin-right: 16px;

          &.live {
            background-color: $success-100;
            border: 1px solid $success-500;
          }

          &.test {
            background-color: $warning-100;
            border: 1px solid $warning-500;
          }
        }
      }
    }

    .topbar-test-pill {
      border: 1px solid $warning2;
      border-radius: 6px;
      color: $warning2;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 18px;
      margin-right: 24px;
      padding: 3px 8px;
      white-space: nowrap;
    }
  }

  .topbar-env-toggle {
    padding-right: 24px;
    margin-right: 24px;
    position: relative;

    .action-text {
      white-space: nowrap;
    }

    .toggle-container {
      margin-left: 26px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      background: $light-grey;
      width: 1px;
      height: 32px;
    }
  }

  .topbar-search,
  .topbar-notifications-icon,
  .topbar-help-icon {
    @include display-flex(flex-start, flex-start);
    cursor: pointer;
    margin-right: 24px;

    @include respond(phone) {
      margin-right: 20px;
    }

    svg {
      width: 24px;
      height: 100%;

      @include respond(phone) {
        width: 20px;
      }

      path {
        fill: $grey;
      }
    }
  }

  .topbar-menu {
    &-link {
      @include display-flex;
      padding: 4px 16px;
      margin-bottom: 8px;
      transition: all ease-in-out 0.3s;
      cursor: pointer;

      &__icon {
        width: 12px;
        @include display-flex(flex-start, flex-start);
      }

      &:hover {
        background: $light;
      }
    }
  }

  .topbar-help {
    position: relative;
    margin-right: 24px;

    &-icon {
      margin-right: 0;
    }

    &-content {
      padding-top: 16px;
      overflow: hidden;
    }

    &-title {
      padding: 0 16px 4px;
      display: flex;
    }

    &-footer {
      padding: 16px;
      background: $light;

      p {
        margin-bottom: 4px;
      }
    }
  }

  .topbar-notifications-icon {
    .badge-container {
      margin-left: -8px;
      margin-top: -9px;

      @include respond(phone) {
        margin-left: -10px;
        margin-top: -2px;
      }
    }
  }

  .topbar-user {
    &-avatar {
      cursor: pointer;
    }

    &-content {
      width: 250px;
      .topbar-menu-link {
        justify-content: flex-start;

        &__icon {
          justify-content: center;
          width: 16px;
          margin-right: 8px;

          svg {
            path {
              stroke: $black;
            }
          }
        }
      }
    }

    &-info {
      padding: 16px;
      position: relative;

      &__name {
        display: flex;
        margin-bottom: 4px;
      }

      &__role {
        text-transform: capitalize;
      }

      &::after {
        content: '';
        position: absolute;
        left: 16px;
        bottom: 0;
        height: 1px;
        width: calc(100% - 32px);
        background: $light-grey;
      }
    }

    &-projects-container {
      padding: 16px 0 12px;
      position: relative;
    }

    &-projects {
      margin: 8px 0 16px;

      &__title {
        display: flex;
        padding: 0 16px 4px;
      }

      &__selected {
        @include display-flex;
        padding: 0 16px;
        cursor: pointer;

        & > div {
          @include display-flex(flex-start);
        }
      }

      &__icon {
        @include display-flex(flex-start, flex-start);
        width: 7px;
      }

      &-others {
        width: 240px;
        border-radius: 6px;
        box-shadow: $popper-shadow;
        border: 1px solid $light-grey;
        background: $white;
        position: absolute;
        top: 16px;
        right: 100%;
        display: none;

        &__title {
          display: flex;
          padding: 16px 16px 4px;
        }
      }

      &:hover {
        .topbar-user-projects-others {
          display: block;
        }
      }
    }

    &-projects__action {
      padding: 0 16px;

      .button {
        width: 100%;
        svg {
          margin-right: 10px;
        }
      }
      a,
      a:hover,
      a:active {
        color: white;
      }
    }

    &-logout {
      padding-top: 12px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 16px;
        top: 0;
        height: 1px;
        width: calc(100% - 32px);
        background: $light-grey;
      }
    }
  }

  .topbar-env-toggle,
  .topbar-search,
  .topbar-help,
  .topbar-user {
    @include respond(phone) {
      display: none !important;
    }
  }

  .topbar-mobile-page {
    display: none;
    color: $dark-grey;
    margin-right: auto;

    @include respond(tab-land) {
      @include display-flex(flex-start);
      padding-left: 16px;
    }

    &__icon {
      @include display-flex(flex-start, flex-start);
      width: 20px;
      margin-right: 8px;
    }
  }

  div.topbar-mobile-menu-toggle {
    display: none;
    width: 24px;

    @include respond(tab-land) {
      @include display-flex(flex-start, flex-start);
    }
  }
}

.dashboard-layout-body {
  @include display-flex(flex-start, flex-start);

  .dashboard-layout-content {
    width: 100%;
  }
}

.sidenav-container {
  min-height: 600px;
  width: 100%;
  max-width: 240px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;

  transition: all ease-in-out 0.3s;
  @include display-flex(flex-start, flex-start, column);

  &.mobile-menu-open {
    @include respond(tab-land) {
      display: flex !important;
    }
  }

  @include respond(tab-land) {
    position: fixed;
    top: $topbar-height;
    left: 0;
    right: 0;
    z-index: 99999;
    border-right: 0;
    height: 100vh;
    min-height: unset;
    overflow: auto;
    background-color: white;
    border-right: 1px solid $grey-variant-200;
    margin-top: -$topbar-height;
    display: none;
  }

  .company-toggle {
    width: 100%;
    margin-bottom: 8px;
    transition: all ease-in-out 0.3s;

    &:hover {
      background: $grey-variant-50;
    }

    .popper-container {
      width: 100%;
    }

    .selected-company {
      @include display-flex(center, center);
      width: 100%;
      height: $topbar-height;
      padding: 16px 20px;
      border-radius: 6px;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      .icon {
        svg {
          height: 8px;
          path {
            stroke: $grey-500;
          }
        }
      }

      .avatar-container {
        font-size: 9px;
        font-weight: 500;
      }

      &.selected {
        background-color: $green-50;
        border-radius: 0;

        &:hover {
          box-shadow: none;
          border-color: $green-50;
        }

        .check-icon {
          path {
            fill: $green-700;
          }
        }
      }

      &.option {
        background-color: white;
        border-color: white;
        border-radius: 0;
        padding: 10px 16px;

        &:hover {
          border-color: $grey-variant-50;
          box-shadow: none;
          background-color: $grey-variant-50;
        }
      }

      p {
        margin-left: 8px;
        margin-right: auto;
      }

      .avatar-container {
        @include full-circle(24px);
      }
    }
  }

  .user-toggle {
    margin-top: auto;
    width: 100%;

    .user-profile {
      padding: 12px 20px;
      @include display-flex(space-between, center);
      cursor: pointer;
      width: 100%;
      max-height: 80px;
      transition: all ease-in-out 0.3s;

      &__content {
        width: 100%;
        @include display-flex(flex-start, flex-start, column);

        .fname {
          color: $grey-800 !important;
        }
        .email {
          color: $grey-700 !important;
        }
      }

      .icon {
        svg {
          height: 8px;
          path {
            stroke: $grey-500;
          }
        }
      }

      &:hover {
        background-color: $grey-variant-50;
      }

      p.body2 {
        color: $grey-800;
        width: 100%;
      }

      p.body3 {
        color: $grey-600;
        overflow-wrap: break-word;
        width: 100%;
      }
    }

    .popper-options {
      left: 9px !important;
      margin-top: 70px !important;

      width: 100px !important;
      border: none !important;
      box-shadow: unset !important;
      background-color: transparent;
    }
  }

  .sidenav-menu {
    height: 100%;
    border-right: 1px solid $light-grey-2;
    width: 64px;
    flex: 0 0 64px;
    padding: 0 16px;

    @include respond(tab-land) {
      width: 100%;
      flex: unset;
      height: auto;
    }

    .sidenav-mobile-search {
      display: none;
      margin-bottom: 24px;

      @include respond(tab-land) {
        display: block;
      }

      .textfield-container {
        height: 48px;
      }
    }

    &-item {
      @include display-flex(flex-start, flex-start);

      @include respond(phone) {
        margin-bottom: 24px;
        cursor: pointer;
      }

      &__link {
        @include display-flex(center);
        width: 24px;
        margin: 0 auto 40px;
        cursor: pointer;

        @include respond(phone) {
          margin: 0 16px 0 0;
        }

        svg {
          path {
            stroke: #737a91;
            transition: all ease-in-out 0.3s;
          }
        }
      }
    }
  }

  .sidenav-submenu {
    position: absolute;
    top: 24px;
    left: 64px;
    width: 200px;
    transition: all ease-in-out 0.3s;

    &.desktopHide {
      display: none;

      @include respond(phone) {
        display: block;
      }
    }

    @include respond(phone) {
      position: relative;
      top: 3px;
      left: unset;
      width: calc(100% - 24px);
    }

    &-title {
      padding: 0 16px;
      margin-bottom: 16px;
      color: $black;
      @include display-flex;
      pointer-events: none;

      @include respond(phone) {
        padding: 0;
        margin-bottom: 0;
        pointer-events: all;
      }

      .action-text {
        @include respond(phone) {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -0.01px;
        }
      }

      &__dropdown {
        display: none;
        width: 12px;
        transition: all ease-in-out 0.3s;

        svg {
          path {
            fill: $grey;
          }
        }

        @include respond(phone) {
          @include display-flex(flex-start, flex-start);
        }
      }
    }

    &-item-container {
      @include respond(phone) {
        margin-top: 24px;
        display: none;
      }
    }

    &-item {
      height: 42px;
      @include display-flex(flex-start);
      padding: 0 16px;
      margin-bottom: 8px;
      cursor: pointer;
      border-left-color: transparent;
      transition: color ease-in-out 0.2s;

      svg {
        path {
          stroke: #737a91;
        }
      }

      .fill {
        path {
          fill: #737a91;
        }
      }

      &.active {
        border-left: 4px solid $primary;
        padding-left: 12px;
        span {
          color: $primary;
        }
        svg {
          path {
            stroke: $primary;
          }
        }
        .fill {
          path {
            fill: $primary;
          }
        }
      }

      @include respond(phone) {
        &.active {
          border-left: none;
          padding-left: 0;
        }
        height: auto;
        padding: 0;
        margin-bottom: 32px;
      }

      &__icon {
        @include display-flex(flex-start, flex-start);
        width: 16px;
        margin-right: 8px;
      }

      &__label {
        color: $dark-grey;
      }

      &:last-child {
        @include respond(phone) {
          margin-bottom: 0;
        }
      }
    }
  }

  .sidenav-menu-item {
    &__link {
      position: relative;
      // width: 100%;

      &-title {
        @include display-flex(center);
        background: $primary;
        color: $white;
        position: absolute;
        left: calc(100% + 21px);
        padding: 4px;
        border-radius: 6px;
        z-index: 100;
        opacity: 0;
        pointer-events: none;
        transition: all ease-in-out 0.3s;

        &::before {
          content: '';
          width: 15px;
          height: 15px;
          background: $primary;
          position: absolute;
          left: -2px;
          transform: rotate(136deg);
          z-index: -1;
        }
      }

      &:hover {
        .sidenav-menu-item__link-title {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &.active {
      .sidenav-menu-item__link {
        svg {
          path {
            stroke: $primary;
          }
        }
      }

      .sidenav-submenu-title__dropdown {
        transform: rotate(180deg);
      }

      .sidenav-submenu-item-container {
        @include respond(phone) {
          display: block;
        }
      }
    }
  }

  .sidenav-mobile-brand-user,
  .sidenav-mobile-brand {
    background: $white;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid $light-grey;
  }

  .sidenav-mobile-brand-user {
    box-shadow: $input-shadow;
    display: none;

    @include respond(phone) {
      display: block;
    }
  }

  .sidenav-mobile-brand {
    @include display-flex(flex-start);

    .dark-grey-font {
      font-size: 1rem;
      line-height: 17px;
      display: flex;
      margin-left: 8px;
    }
  }

  .sidenav-mobile-user {
    margin-top: 16px;
    @include display-flex;

    & > div {
      @include display-flex(flex-start);
    }

    .avatar-container {
      margin-right: 8px;
    }
  }

  .sidenav-mobile-logout {
    padding-left: 16px;
    border-left: 1px solid $light-grey;

    &__icon {
      margin-right: 8px;
      @include display-flex(flex-start, flex-start);
    }
  }

  .sidenav-mobile-more {
    padding: 0 16px;
    width: 100%;

    &__back {
      @include display-flex(center);
      width: 32px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid $light-grey;
      cursor: pointer;
      margin-bottom: 32px;

      svg {
        width: 16px;
      }
    }

    &__link {
      margin-bottom: 16px;
      cursor: pointer;
      @include display-flex;

      &-icon {
        @include display-flex(flex-start, flex-start);
      }

      &:first-of-type {
        margin-top: 12px;
      }
    }

    .sidenav-mobile-current-project {
      @include display-flex(flex-start);
      padding: 8px 16px;
      border: 1px solid $light-grey;
      border-radius: 6px;
      margin: 8px 0 16px;
    }

    .sidenav-mobile-add-project {
      width: 100%;
    }

    .sidenav-mobile-switch-project {
      margin: 16px -16px 20px;
      padding: 0 16px 28px;
      border-bottom: 1px solid $light-grey;

      span {
        display: flex;

        &:first-child {
          margin-bottom: 12px;
        }
      }
    }
    .topbar-env-toggle {
      @include display-flex;
      span {
        margin-right: 12px;
      }
    }
  }

  .sidenav-toggle {
    @include full-circle(24px);
    @include display-flex(center);
    background: $white;
    border: 1px solid $light-grey;
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: -12px;

    @include respond(phone) {
      display: none;
    }

    svg {
      width: 5px;
      transition: all ease-in-out 0.3s;
    }
  }

  &.closed {
    width: 64px;

    .sidenav-submenu {
      opacity: 0;
      visibility: hidden;
    }

    .sidenav-toggle {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.active {
    @include display-flex(flex-start, flex-start);
  }
}

.topbar-notifications-item {
  padding: 24px 16px;
  border-bottom: 1px solid $light-grey;
  a.lone {
    display: block;
    color: $primary;
    margin-top: 6px;
    font-weight: 500;
  }
  button {
    margin-top: 6px;
  }
}

.topbar-support {
  @include respond(phone) {
    .popper-options {
      margin-right: -100px;
    }
  }

  &-avatar {
    cursor: pointer;
    @include display-flex(center, center);
    padding: 5px 12px;
    border-radius: 6px;
    transition: all ease-in-out 0.3s;

    svg {
      margin-right: 8px;
      height: 16px;
      width: 16px;
    }

    p {
      color: $grey-800;
      font-size: 14px;
    }

    &:hover {
      background: $grey-variant-50;
    }
  }

  &-content {
    width: 251px;
    padding: 8px 0;

    .support_section {
      &__header {
        text-transform: uppercase;
        font-weight: 500;
        padding: 8px 16px;
        color: $grey-400;
        font-size: 14px;
      }

      .contact-support {
        cursor: pointer;
        @include display-flex(flex-start, center);
      }

      &__item {
        padding: 8px 16px;

        &:hover {
          background: $light;
        }

        p {
          margin-left: 16px;
          font-size: 14px !important;
        }

        a {
          @include display-flex(flex-start, center);
        }
      }
    }
  }
}

.topbar-dropdown,
.company-select-dropdown,
.user-toggle-dropdown {
  width: 220px;

  .topbar-dropdown_section {
    &__header {
      text-transform: uppercase;
      font-weight: 500;
      padding: 8px 16px;
      color: $grey-400;
      font-size: 14px;
    }

    &__item {
      &.active {
        background-color: $green-50;

        svg {
          path {
            fill: $green-700;
          }
        }
      }

      padding: 8px 16px;
      @include display-flex(space-between, center);

      &:hover {
        cursor: pointer;
        background: $light;
      }

      p {
        font-size: 14px !important;
      }
    }
  }
}

.sidebar-menu-container::-webkit-scrollbar {
  background: none;
  width: 8px;
}
.sidebar-menu-container::-webkit-scrollbar-thumb {
  background: $grey-variant-100;
  border-right: 2px solid rgb(255, 255, 255);
  border-radius: 100px;
}

.sidebar-menu-container {
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  @include display-flex(flex-start, center, column);

  .navitem-divider {
    width: 100%;
    height: 1px;
    background-color: $grey-variant-200;
    width: 208px;
    margin: 8px 0;
  }

  .active-route {
    background-color: $green-50;
    border-radius: 8px;

    p.body3 {
      color: $green-700;
      font-weight: 600;
    }

    svg {
      path {
        stroke: $green-700 !important;
      }
    }
  }

  .route-icon {
    width: 16px;
    @include display-flex(center, center);

    svg {
      path {
        stroke: $grey-500;
      }
    }
  }

  .navitem-section {
    width: calc(100% - 24px);

    .navitem-primary {
      margin-bottom: 2px;
      height: 38px;
      padding: 0 12px;
      @include display-flex(flex-start, center);
      cursor: pointer;
      border-radius: 8px;
      transition: all ease-in-out 0.3s;

      &[data-state='animate'] {
        .route-icon {
          transition: all 0.3s ease;
          transform: rotateZ(90deg);
        }
      }

      &[data-state='show-children'] {
        .route-icon {
          transform: rotateZ(90deg);
        }
      }

      &:hover {
        background-color: $grey-variant-50;
      }

      p {
        margin-left: 16px;
      }

      &__icon {
        width: 16px;
        @include display-flex(center, center);
      }
    }

    .navitem-secondary {
      @include display-flex(flex-start, center);
      width: calc(100% - 28px);
      height: 38px;
      margin: 0 0 2px 28px;
      padding: 0 12px;
      cursor: pointer;
      border-radius: 8px;
      transition: all ease-in-out 0.3s;

      &:hover {
        background-color: $grey-variant-50;
      }

      &__icon {
        @include display-flex(center, center);
      }

      p {
        margin-left: 16px;
      }

      .status-chip {
        margin-left: auto;
        text-transform: uppercase;
        border: 1.5px solid $info-500;
        font-size: 10px;
        font-weight: 600;
      }

      .badge {
        @include display-flex(center, center);
        margin-left: auto;
        background-color: $critical-500;
        width: 18px;
        height: 16px;
        font-weight: 500;
        color: $white;
        font-size: 10px;
        border-radius: 999px;
      }
    }

    &:last-child {
      padding-bottom: 8px;
    }
  }
  .navitem-section-group {
    width: calc(100% - 24px);

    .navitem-secondary {
      width: 100%;
      margin-left: 0;

      p {
        font-weight: 500;
      }
    }
  }

  .navitem-route {
    @include display-flex(flex-start, center);
    width: calc(100% - 24px);
    min-height: 38px;
    height: 38px;
    margin-left: 0;
    margin-bottom: 2px;
    padding: 0 12px;
    cursor: pointer;
    border-radius: 8px;
    transition: all ease-in-out 0.3s;

    &:hover {
      background-color: $grey-variant-50;
    }

    p {
      margin-left: 16px;
    }
  }

  .navitem-group {
    width: 100%;
    padding: 8px 12px;
    margin: 14px 0 2px;
    color: $grey-400;

    &.MANAGE {
      padding-left: 26px;
    }
  }

  .BUSINESS {
    display: none;
  }
}

// component-select-dropdown
.company-select-dropdown {
  width: 256px;
  margin-left: 6px;
  border: 1px solid $grey-variant-200;
  padding: 6px 0 !important;

  padding: 6px 0;

  .company-select_footer {
    padding: 12px;
    padding-bottom: 6px;
    border-top: 1px solid $grey-variant-100;
    margin-top: 4px;

    .button {
      width: 100%;

      svg {
        width: 12px;
      }
    }
  }

  .see-all-btn {
    margin-bottom: 4px;
    .list-item__title {
      color: $green-700;
    }
  }
}

// user settings toggle
.user-toggle-dropdown {
  width: 240px;
  margin-left: 6px;
  padding: 4px 0;
  background-color: $white;
  border-radius: 6px;
  padding: 0;
  z-index: 100;
  box-shadow: $popper-shadow;
  border: 1px solid $light-grey;

  .dropdown-option {
    @include display-flex(flex-start, center);
    padding: 8px 18px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $grey-50;
    }

    p {
      margin-left: 16px;
      color: $grey-900;
    }

    svg {
      path {
        stroke: $grey-500;
      }
    }
  }
}
