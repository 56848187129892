.payment-link-form {
  height: 100%;
  overflow: auto;
  padding-bottom: 80px;

  &__body {
    padding: 24px;
    height: calc(100vh - 122px);
    overflow: auto;

    & > .input-container {
      margin-bottom: 32px;
    }
  }

  .date-picker-links {
    input {
      cursor: pointer;
    }
    input::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }

  .input-container {
    .input-container {
      margin-bottom: 0;
    }

    .variable-checkbox {
      margin-top: 20px;

      .checkbox-label {
        color: $dark-grey;
        margin-left: 8px;
      }
    }
  }

  &__footer {
    border-top: 1px solid $light-grey;
    background: $white;
    padding: 16px;
    @include full-fixed;
    top: unset;
    @include display-flex(flex-end);

    .button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .link-types-container {
    .link-types-heading {
      margin-bottom: 8px;
    }

    .link-type {
      margin-top: 24px;

      &__title {
        margin-bottom: 8px;
        display: block;
      }

      .radio-display-container {
        padding: 24px;
        align-items: flex-start;
      }
    }
  }

  .payment-advance-option {
    margin-bottom: 24px;

    .button-icon {
      svg {
        transition: all ease-in-out 0.2s;

        path {
          fill: $dark-grey;
        }
      }
    }

    &.advanceOptions {
      .button-icon {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .extra-information {
    label {
      display: block;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      padding-bottom: 8px;
      color: $black;
    }

    .input-container {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-bottom: 16px;
      }
    }

    &__item {
      @include display-flex(flex-start);

      &-remove {
        display: flex;
        margin-left: 16px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }

  .extra-button {
    svg {
      path {
        fill: $primary;
      }
    }
  }
}
