.notification {
  &--section {
    @include display-flex(unset, baseline);
    @include respond(tab-land) {
      flex-wrap: wrap;
    }
  }
  &--learn-more {
    margin-top: 0.8em;
    @include respond(phone) {
      margin-bottom: 0.9em;
    }
  }
  &--desc {
    color: $dark-grey;
    font-weight: normal;
  }
  .icon-holder {
    @include display-flex(space-between, center);
    svg {
      margin-right: 0.4em;
    }
  }
  &--left {
    margin-bottom: 2em;
    margin-right: 2em;
    width: 100%;
    @include respond(tab-land) {
      margin-right: unset;
    }
    .left-card {
      .btn {
        color: $critical;
        border-color: $critical;
      }
      padding: 0;
    }
  }
  &--right {
    width: 100%;
    .right-card {
      .card {
        border: 1px $light-grey solid;
      }
      margin-bottom: 2em;
    }
  }
}
