.request-access {
  &-container {
    height: calc(100vh - 122px);
    overflow: auto;
    padding-bottom: 24px;
  }

  &-header {
    margin: 0 24px;
    padding: 24px 0;
    border-bottom: 1px solid $light-grey;
    @include display-flex(flex-start, flex-start);

    &__icon {
      display: flex;
      margin-right: 16px;
    }
  }

  &-section {
    padding: 24px 0;
    margin: 0 24px;
    display: flex;
    border-bottom: 1px solid $light-grey;

    &:last-child {
      border-bottom: 0;
    }

    &__icon {
      display: flex;
      width: 24px;
      margin-right: 16px;

      svg {
        path {
          fill: $dark-grey;
        }
      }
    }

    &__container {
      width: 100%;
    }

    &__header {
      @include display-flex();
      margin-bottom: 4px;
    }

    .cac-forms-inputs {
      & > * {
        margin-top: 16px;
      }
    }

    .upload-instructions {
      margin-top: 16px;

      ul {
        margin-left: 20px;
        margin-top: 8px;
      }
    }

    .radio-container {
      margin-top: 16px;
    }

    .select-container,
    .input-container {
      margin-top: 24px;
    }

    .file-uploader {
      width: 100%;
    }

    ul,
    li {
      list-style-type: disc;
    }

    &__radio-group {
      .radio {
        margin-top: 12px;
      }
    }

    &__required {
      color: $critical;
      padding-left: 12px;
    }

    &.main-reg-license {
      border-bottom: 1px solid $light-grey;
      padding-bottom: 24px;

      .dark-grey-font {
        margin-bottom: 12px;
      }
    }

    &__ids {
      flex-direction: column;
      padding: 0 0 24px 0;
      .textfield-control {
        margin: 12px 0 8px;
      }
      .request-access-section {
        margin: 0;
      }
      .primary-button {
        width: fit-content;
        margin-left: auto;
        margin-top: 12px;
      }
    }
    &__main-id {
      border-bottom: none;
      padding-bottom: 0;
    }
    &__extras-id {
      border-bottom: none;
      padding: 0;
      .request-access-section {
        &__icon {
          opacity: 0;
          pointer-events: none;
        }
        &__header {
          display: none;
        }
      }
      .dark-grey-font {
        opacity: 0;
        pointer-events: none;
        line-height: 0px;
      }
    }
  }

  &-footer {
    border-top: 1px solid $light-grey;
    background: $white;
    padding: 16px;
    @include full-fixed;
    top: unset;
    @include display-flex(flex-end);

    .button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
