.onboarding-layout {
  min-height: 100vh;
  background-color: $light;

  &.plain {
    background: none;
  }
  &-body {
    width: 100%;
    min-height: 100vh;
    z-index: 1;

    &-children {
      min-height: 100vh;
    }
  }

  .left-background-image {
    position: absolute;
    bottom: 0;
    z-index: 0;
  }

  .right-background-image {
    display: block;
    position: absolute;
    top: 0;
    z-index: 0;
    right: 0;
  }

  &-body-children {
    z-index: 1;
    position: relative;
  }

  .desktop-only {
    display: none;

    @media only screen and (min-width: 75em) {
      display: block;
    }
  }
}
