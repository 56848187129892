.personal-information {
  h4 {
    border-bottom: 1px $light-grey solid;
    padding-bottom: 24px;
    margin-bottom: 24px;
    &.padding-top {
      margin-top: 40px;
    }
  }
  .row {
    display: flex;
    div:first-child {
      margin-right: 32px;
    }
    &.full {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      @include respond(tab-land) {
        align-items: flex-start;
      }
    }
    @include respond(tab-land) {
      flex-direction: column;
      div:first-child {
        margin-right: 0;
      }
    }
  }
  .input-container {
    max-width: 320px;
    margin-bottom: 32px;
  }
  p.semibold {
    margin-top: 40px;
  }
  .toggle {
    width: auto;
    @include respond(tab-land) {
      width: 100%;
      margin-top: 12px;
    }
  }
}

.profile {
  overflow: hidden;

  .card-info {
    @include respond(tab-port) {
      margin-top: 6em;
    }
    border-bottom: 1px $light-grey solid;
    margin-bottom: 3em;
    &.off-border {
      border-bottom: none;
      margin-bottom: unset;
    }
    &.factor-flex {
      border-bottom: none;
      @include display-flex(space-between, baseline);
      @include respond(tab-port) {
        display: block;
      }
      p {
        width: 57%;
        @include respond(tab-port) {
          width: auto;
        }
      }
      .toggle {
        width: auto;
      }
    }
    .two-factor,
    .info-name {
      margin-bottom: 1.1em;
    }
    .medium {
      color: $dark-grey;
      margin-bottom: 1em;
      &.override {
        color: transparent;
      }
    }
    .info-btn {
      margin: 2em 0;
    }

    .input-holder {
      margin-bottom: 2em;
      .new-director {
        margin-top: 1em;
      }
      .info-input {
        width: 100%;

        .input-container {
          width: 100%;
        }

        .single-div {
          margin-right: 1em;
          width: 100%;
          @include respond(tab-port) {
            width: auto;
          }
        }
        .un-editable,
        #un-editable {
          pointer-events: none;
        }
        &.password {
          width: 50%;
          @include respond(tab-port) {
            width: auto;
          }
        }
        .input {
          margin-bottom: 2em;
          margin-right: 1em;
        }
        &.info-flex {
          @include display-flex(unset);
          @include respond(tab-port) {
            display: block;
          }
        }
      }
    }
  }
}

.reset-password {
  .passwordChecker {
    max-width: 320px;

    &.revealOptions {
      margin-top: 0px;
      height: 75px;

      @include respond(phone) {
        margin-top: -15px;
      }
    }

    &__bars {
      gap: 5px;

      &__red,
      &__yellow,
      &__green {
        gap: 5px;
        & > span {
          position: relative;
          width: 31px;
        }
      }
    }

    &__citeria {
      flex-direction: column;
      &__text {
        & > P {
          font-size: 15px;
          color: $grey;
        }
      }
      &__list {
        margin-top: 5px;

        & > P {
          justify-content: space-between;
        }
      }
    }
  }
}
