$customer-nav-height: 40px;

.customer-v2 {
  &--single-Table {
    margin-top: 2em;
  }

  .page-title {
    margin-bottom: 2rem;

    p {
      margin-top: 0.5rem;
    }
  }

  .nuban-details {
    @include ellipsis();
    max-width: 200px;
  }

  &--crumbs-holder {
    margin-bottom: 2em;
    @include display-flex(space-between, flex-start);
    @include respond(tab-port) {
      display: block;
    }
    .env {
      @include respond(tab-port) {
        margin-top: 1em;
      }
      .env-value {
        color: $primary;
        text-transform: capitalize;
      }
    }
  }

  &--card {
    border: 1px solid $light-grey;
    padding: 0 !important;

    .card-profile {
      @include respond(tab-port) {
        display: block;
      }
      border-bottom: 1px solid $light-grey;
      padding: 2em;
      @include display-flex(space-between, flex-start);
      .profile-avatar {
        @include respond(tab-port) {
          margin-bottom: 2em;
          width: auto;
        }
        @include display-flex(flex-start);
        width: 302px;
        .avatar-name {
          margin-left: 1em;
        }
      }
      .profile-button {
        @include respond(tab-land) {
          width: 50%;
        }
        @include respond(tab-port) {
          width: auto;
        }
        width: calc(100% - 390px);
        .button-holder {
          @include display-flex(flex-start);
          flex-wrap: wrap;
          .disable {
            pointer-events: none;
            cursor: no-drop;
          }
        }
      }
      .btn {
        margin: 0.6em 1em 0.6em 0;
      }
      #svg {
        margin-left: 0.7em;
        path {
          stroke: $white;
        }
      }
    }
    .customer-details {
      @include respond(tab-port) {
        display: block;
      }
      padding: 2em;
      @include display-flex();
      .photo-id {
        @include respond(tab-port) {
          width: 100%;
        }
        border-radius: 10px;
        height: 330px;
        width: 320px;
        display: block;
        overflow: hidden;
        background: #ededed;
        &.image {
          display: grid;
          place-items: center;
          p {
            margin-top: 15.47px;
            color: #737a91;
          }
        }

        .photo-container {
          height: 100%;

          img {
            height: 100%;
          }
        }
      }
      .customer-info {
        @include respond(tab-land) {
          width: 50%;
          margin-top: 2em;
        }
        @include respond(tab-port) {
          margin-left: unset;
          width: auto;
        }
        width: calc(100% - 390px);
        margin-left: 2em;
        .row-data {
          border-bottom: 1px solid $light-grey;
          padding: 1rem 0;
          @include display-flex();
          flex-wrap: wrap;
          .email {
            text-transform: lowercase;
          }
          &:last-child {
            border: 0;
            padding-bottom: 0;
          }
          &:first-child {
            padding-top: 0;
          }
          .head {
            margin-right: 2em;
          }
          .owners-pills {
            div {
              margin: 0.1em;
            }
            width: 50%;
            @include display-flex(flex-end);
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .empty_customer {
    @include display-flex(center, center);
    min-height: 700px;
    background: $white;
    border: 1px solid $light-grey;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(185, 188, 201, 0.16);

    @include respond(tab-port) {
      min-height: 70vh;
      padding: 0 16px;
    }

    &__content {
      text-align: center;
      max-width: 360px;
      color: $grey-900;

      span {
        svg {
          margin-bottom: 56px;
          width: 64px;
          height: 64px;

          @include respond(phone) {
            margin-bottom: 32px;
            width: 48px;
            height: 48px;
          }

          path {
            stroke: $grey-500;
          }
        }
      }

      & p {
        margin-bottom: 8px;
      }
      .body3 {
        color: $grey-700;
      }
    }

    &__buttons {
      @include display-flex(center, center);
      margin-top: 24px;
      gap: 24px;

      @include respond(phone) {
        @include display-flex(center, center, column);
        gap: 16px;
      }
    }

    &__prod {
      @include display-flex(flex-start, flex-start, column);
      padding: 24px;
      span {
        @include display-flex(flex-start, center);
        gap: 8px;
        padding: 12px;
        width: 100%;
        border-top: 1px solid $grey-200;
        border-bottom: 1px solid $grey-200;
        .caption {
          color: $grey-700;
        }
      }
      .caption.medium {
        padding: 12px;
        color: $grey-500;
      }
    }
  }
}

.projects-create-container.customer_drawer {
  max-height: 92vh;
  overflow: scroll;
  padding-top: 0;
  margin-bottom: 32px;
  .select-container {
    margin-top: 0;
  }
  .input-container {
    margin-top: 16px;
  }

  .create_text {
    @include display-flex(center, center);
    color: $grey-700;
    position: relative;
    margin: 16px 0;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $grey-200;
      left: 0;
      top: 35%;
      z-index: -1;
    }

    & p {
      background: $white;
      width: fit-content;
      padding: 0 16px;
    }
  }

  .account-types__options {
    margin-top: 16px;
    p {
      margin-bottom: 8px;
      color: $grey-800;
    }
  }
  .radio:last-child {
    margin-bottom: 0;
  }

  .section {
    position: relative;

    p {
      margin: 16px 0 0;
      padding-bottom: 16px;
      width: 100%;
      color: $grey-400;
      border-bottom: 1px solid $grey-400;
    }
  }

  .customer_last-input {
    margin-bottom: 80px;
  }
}

.verification-drawer-footer.customer__footer {
  position: fixed;
  padding: 12px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $white;
  padding: 12px;

  gap: 16px;
}

.full-width {
  width: 100%;
  margin-top: 10px;
}

.table-sticky-fix {
  .table-card--sticky {
    .table-topbar-container {
      top: $customer-nav-height + $table-topbar-top;
    }

    .table-container table tr th {
      top: $customer-nav-height + $table-topbar-height + $table-topbar-top;
    }
  }
}

.single-customer {
  height: calc(100vh - 56px);
  background-color: white;
  overflow: auto;

  .breadcrumbs-container .breadcrumbs-item {
    max-width: 100%;
  }

  p,
  span,
  h1,
  h2,
  h3,
  h4 {
    color: $grey-900 !important;
  }

  .subtitle {
    color: $grey-700 !important;
  }

  nav {
    z-index: 99996;
    height: $customer-nav-height;
    padding: 0 24px;
    @include display-flex(flex-start, center);
    border-bottom: 1px solid $grey-variant-200;
    background-color: white;

    position: sticky;
    top: 0;
    width: 100%;

    .button-text {
      font-weight: 600;
      font-size: 14px !important;
    }

    .record-control {
      margin-left: auto;
      @include display-flex(flex-start, center);
      gap: 12px;

      @include respond(tab-port) {
        display: none;
      }

      caption {
        color: $grey-700;
      }
    }
  }

  .with-copy {
    @include display-flex(flex-start, center);
    height: 24px;
    cursor: pointer;

    &:hover {
      .copy-element {
        display: flex;
      }
    }

    .copy-element {
      display: none;
      @include respond(tab-port) {
        display: flex;
      }
    }
  }

  .customer-identity {
    &__details {
      padding: 24px;
      @include display-flex(flex-start, center);
      gap: 16px;

      &.mobile-only {
        padding: 24px 16px;
      }

      .content {
        width: 100%;
        h4 {
          margin-bottom: 8px;
          @include respond(tab-port) {
            font-size: 18px;
          }
        }
      }

      .user-image {
        width: 64px;
        height: 64px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 1px solid $grey-variant-100;
          border-radius: 9999px;
        }
      }
    }
  }

  .content-controls {
    height: 56px;
    padding: 0 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $grey-variant-200;
    gap: convertPxToRem(8);
    @include display-flex(flex-start, center);

    @include respond(tab-port) {
      width: 100%;
      overflow-x: scroll;
      padding: 30px 16px;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }

    .delete-customer {
      margin-left: auto;
    }

    .option {
      @include display-flex(center, center);
      height: 32px;
      padding: 0 12px;
      border-radius: 100px;
      color: $grey-800;
      border: 1px solid $grey-variant-200;
      cursor: pointer;

      @include respond(tab-port) {
        height: 40px;
      }

      &.active {
        background-color: $grey-900;

        p {
          color: $white !important;
        }

        border-color: $grey-900;
      }
    }
  }

  .content-row {
    @include display-flex(flex-start, flex-start);
    margin-bottom: 4px;

    .key,
    .value {
      width: 50%;
      flex-shrink: 0;
    }

    .key {
      p {
        padding: 8px 0 8px;
      }
    }

    .value {
      @include display-flex(flex-start, flex-start, column);
      flex-wrap: wrap;
      height: auto;
      padding: 8px;

      p {
        width: 100%;
        overflow-wrap: break-word;
        color: $grey-900;
        flex-wrap: wrap;
      }
    }
  }

  .flex {
    @include display-flex(flex-start, center);
  }

  .customer-user-details {
    @include display-flex(space-between, center);
    margin-bottom: 16px;
    gap: 16px;

    .with-copy {
      @include display-flex(flex-start, center);
    }
  }

  .section-header {
    padding: 12px 0;
    border-bottom: 1px solid $grey-variant-200;
    margin-bottom: 16px;

    p.body2.semibold.padded-left {
      span {
        margin-left: 4px;
      }
    }
  }

  .card {
    padding: 0;
    border-width: 0;
    box-shadow: none;
    margin: 0;

    @include respond(tab-port) {
      border-width: 1px;
    }
  }

  .customer-overview {
    @include display-flex(flex-start, flex-start);
    min-height: calc(100vh - 268px);
    align-items: stretch;

    &__lhs {
      width: 360px;
      padding: 24px;
      border-right: 1px solid $grey-variant-200;
      flex-shrink: 0;
      align-self: stretch;

      @include respond(tab-port) {
        display: none;
      }
    }

    &__rhs {
      padding: 24px;
      width: 100%;
      height: 100%;

      @include respond(tab-port) {
        padding: 24px 16px;
      }
    }

    .padded-left {
      padding-left: 12px;
    }

    .section {
      margin-bottom: 16px;
    }

    .test-data {
      .content-row {
        .bank-cover__logo {
          width: 24px !important;
          height: 24px !important;
          background-size: contain;
        }

        .bank-cover__details {
          p {
            font-size: 14px;
          }
        }
      }
    }

    .recent-activities {
      padding-bottom: 40px;

      .activities {
        margin-top: 24px;
      }

      .activity {
        @include display-flex(flex-start, flex-start);
        gap: 16px;
        margin-bottom: 8px;

        &__icon {
          @include display-flex(flex-start, center, column);
          gap: 12px;
          align-self: stretch;
          flex-shrink: 0;

          svg {
            flex-shrink: 0;
          }

          .vt {
            width: 1px;
            height: 100%;
            background-color: $grey-variant-200;
          }
        }

        .primary-text {
          color: $grey-900;
        }

        &__details {
          @include display-flex(flex-start, flex-start, column);
          gap: 4px;
          padding-bottom: 16px;

          .link-button-icon {
            display: none;
          }

          button {
            padding: 0;
            height: auto;
            border-radius: 0;
          }
        }
      }
    }
  }

  .desktop-only {
    @include respond(tab-port) {
      display: none;
    }
  }

  .account-selector {
    @include display-flex(flex-start, center);
    padding: 0 8px;
    margin: 16px 0;

    .flex {
      @include display-flex(flex-start, center);
      gap: 12px;
      height: 40px;
      cursor: pointer;
    }

    .bank-cover__logo {
      width: 24px !important;
      height: 24px !important;
      background-size: contain;
    }

    .bank-cover__details {
      p:first-child {
        display: none;
      }

      p {
        font-size: 14px;
        margin-left: 12px;
      }
    }

    .sort-icon {
      @include display-flex(center, center);
    }
  }

  .dropdown-container {
    width: 280px;

    .list-item__title.medium {
      font-weight: 500;
    }

    .bank-cover__details {
      display: none;
    }

    .bank-cover__logo {
      width: 24px !important;
      height: 24px !important;
      background-size: contain;
    }

    .list-item__title.medium {
      margin-bottom: 0;
      line-height: auto;
    }
  }

  .account-bankcover {
    .bank-cover__logo {
      width: 32px !important;
      height: 32px !important;
      background-size: contain;
    }

    .bank-cover__details {
      p {
        font-size: 14px;
      }
    }
  }

  // transaction section
  .customer-transactions {
    height: calc(100vh - 268px);
    padding: 24px;
    .transaction-amount {
      &.credit {
        color: $success-500 !important;
      }

      &.debit {
        color: $critical-500 !important;
      }
    }

    .transaction-overview {
      @include display-flex(flex-start, center);
      border-bottom: 1px solid $grey-variant-200;
      border-top: 1px solid $grey-variant-200;

      @include respond(tab-port) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        border-width: 0px;
      }

      .transaction-container {
        flex: 1;
        padding: 24px 16px;
        height: 102px;
        @include display-flex(flex-start, flex-start);
        gap: 12px;

        @include respond(tab-port) {
          width: 100%;
          padding: 24px 0px;
        }

        &:nth-child(2) {
          border-left: 1px solid $grey-variant-200;
          border-right: 1px solid $grey-variant-200;

          @include respond(tab-port) {
            border-right-width: 0px;
            border-left-width: 0px;

            border-bottom: 1px solid $grey-variant-200;
            border-top: 1px solid $grey-variant-200;
          }
        }

        .icon {
          width: 32px;
          height: 32px;
        }

        &__content {
          @include display-flex(flex-start, flex-start, column);
        }
      }
    }

    .account-transaction {
      margin-top: 24px;
    }
  }

  // identity section

  .identity-column {
    padding-bottom: 24px;
    @include respond(tab-port) {
      padding: 24px;
    }
  }

  // income section
  .customer-income {
    padding: 24px 24px 0;
    min-height: calc(100vh - 268px);

    .dashboard-tabs {
      height: 100%;

      .tabs-content {
        padding: 0 !important;
        height: 100%;
      }
    }

    .all-accounts {
      @include display-flex(flex-start, center);
      gap: 24px;
      margin-left: 10px;

      .bank-logos {
        @include display-flex(flex-start, center);

        .bank-cover {
          border: 2px solid white;
          border-radius: 9999px;
          margin-left: -10px;
          &__details {
            display: none;
          }

          &__logo {
            width: 24px !important;
            height: 24px !important;
            background-size: contain;
          }
        }
      }
    }

    .income-summary {
      @include display-flex(flex-start, stretch);

      &__overview,
      &__history {
        .row {
          padding: 8px 0;
          margin-bottom: 4px;
          align-items: flex-start;

          .label {
            align-items: flex-start;
          }

          .value {
            align-items: flex-start;
          }
        }
      }

      &__overview {
        width: 400px;
        border-right: 1px solid $grey-variant-200;
        padding: 0 24px 24px 0;
      }

      &__history {
        flex: 1;
        padding: 0 24px 24px;
      }
    }

    .section-header {
      @include display-flex(flex-start, center);
    }
  }

  .row {
    @include display-flex(flex-start, center);
    padding: 8px 0;
    margin-bottom: 12px;

    @include respond(tab-port) {
      align-items: flex-start;
    }

    .label,
    .value {
      flex: 1;
      @include display-flex(flex-start, center);

      @include respond(tab-port) {
        flex-shrink: 0;
        width: 50%;
        flex-wrap: wrap;
      }
    }

    .value {
      padding-left: 8px;
      @include display-flex(flex-start, center);
      max-width: 100%;

      &.is_total {
        border-top: 1px dashed $grey-variant-100;
        padding-top: 4px;

        p {
          color: $success-500 !important;
        }
      }
    }
  }

  .table-topbar-container {
    border-top-width: 0px !important;
  }

  .empty-column-details {
    @include display-flex(flex-start, flex-start);
    gap: 12px;

    @include respond(tab-port) {
      display: none;
    }

    .icon {
      @include display-flex(center, center);
    }

    p.subtitle {
      margin-top: 8px;
    }
  }

  // payment section
  .customer-payment {
    height: calc(100vh - 268px);

    .section {
      padding: 24px;
    }

    .payment-bank {
      .bank-cover__logo {
        width: 32px !important;
        height: 32px !important;
      }

      .list-item__title.medium {
        margin-bottom: 0;
        line-height: auto;
      }

      .bank-cover__details {
        margin-left: 12px;
      }

      p {
        &:last-of-type {
          color: $grey-700 !important;
          font-size: 12px;
        }
      }
    }

    .payment-status {
      text-transform: uppercase;
    }

    .customer-payment-content {
      @include display-flex(flex-start, stretch);
      min-height: calc(100vh - 268px);

      @include respond(tab-port) {
        flex-wrap: wrap;

        &.is-not-active {
          height: auto;
        }
      }

      .customer-payment-table {
        flex: 1;
        padding: 24px;
        border-right: 1px solid $grey-variant-200;

        @include respond(tab-port) {
          padding: 0;

          .td__container {
            padding: 0;

            .td-render {
              padding: 0;
              margin-bottom: 8px;
            }
          }
        }
      }

      .customer-payment-details {
        width: 400px;
        box-sizing: border-box;
        padding: 24px;
        display: flex;
        height: 100%;
        position: sticky;
        top: $customer-nav-height;

        @include respond(tab-port) {
          padding: 0;
          width: 100%;
          position: absolute;
          height: auto;
          top: 0;
        }

        .payment-details {
          width: 100%;
          .header {
            display: none;
          }

          @include respond(tab-port) {
            width: 100%;
            z-index: 999999;
            height: 100vh;
            position: absolute;
            top: 0;
            background-color: white;

            .header {
              display: flex;
              border-bottom: 1px solid $grey-variant-200;
              padding: 24px;
              @include display-flex(space-between, center);
            }

            .section {
              padding: 0 16px !important;
            }
          }

          .hr-divider {
            margin: 12px 0;
            height: 1px;
            width: 100%;
            background-color: $grey-variant-200;
          }

          .section {
            padding: 0;
            margin-top: 12px;

            h4 {
              margin-bottom: 8px;
            }
          }

          button {
            margin-top: 12px;

            svg {
              path {
                fill: transparent !important;
              }
            }
          }

          .bank-cover {
            p {
              &:last-of-type {
                font-size: 14px;
                color: $grey-900 !important;
              }
            }
          }

          .callout {
            @include display-flex(flex-start, flex-start);
            padding: 16px;
            border-radius: 12px;
            background-color: $neutral-50;
            margin-top: 24px;
            gap: 12px;

            .icon {
              @include display-flex(flex-start, flex-start);

              svg {
                path {
                  &:first-of-type {
                    fill: $neutral-500;
                  }
                }
              }
            }

            .content {
              p.subtitle {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  // customer records
  .customer-records {
    height: calc(100vh - 268px);

    .empty-records-state {
      padding: 24px;
    }

    .used-products {
      .status-chips {
        @include display-flex(flex-start, center);
        gap: 4px;
        margin-bottom: 4px;
      }
    }

    .customer-records-content {
      @include display-flex(flex-start, stretch);
      min-height: calc(100vh - 268px);

      @include respond(tab-port) {
        flex-wrap: wrap;
      }

      .records-table {
        padding: 24px;
        width: 100%;
        flex: 1;
        flex-shrink: 0;
        border-right: 1px solid $grey-variant-200;

        @include respond(tab-port) {
          padding: 0;

          .td__container {
            padding: 0;

            .td-render {
              padding: 0;
              margin-bottom: 8px;
            }
          }
        }
      }

      .record-details {
        width: 400px;
        flex-shrink: 0;
        padding: 24px;
        display: flex;
        align-items: stretch;
        height: 100%;
        position: sticky;
        top: $customer-nav-height;

        @include respond(tab-port) {
          width: 100%;
          padding: 0;
          position: absolute;
          top: 0;

          &.is-not-active {
            height: auto;
            outline: 1px dotted;

            * {
              outline: 1px dotted;
            }
          }
        }

        .header {
          display: none;
        }
      }

      .record-details-view {
        width: 100%;

        .record-header-mobile {
          .section-header {
            svg {
              display: none;
            }
          }
        }

        @include respond(tab-port) {
          width: 100%;
          z-index: 999999;
          height: 100vh;
          position: absolute;
          top: 0;
          background-color: white;
          padding: 0;

          .section {
            padding: 0 16px;
            margin-top: 12px;

            h4 {
              margin-bottom: 8px;
            }
          }

          .record-header-mobile {
            padding: 0;
            .section-header {
              @include display-flex(space-between, center);
              padding: 8px 24px 16px;
              svg {
                display: block;
              }
            }
            .row {
              padding: 8px 16px;
            }
          }

          .header {
            display: none;
          }
        }
      }
    }

    .bank {
      .bank-cover__logo {
        width: 24px !important;
        height: 24px !important;
      }

      .list-item__title.medium {
        margin-bottom: 0;
        line-height: auto;
      }

      .bank-cover__details {
        margin-left: 12px;
      }

      p {
        &:last-of-type {
          color: $grey-900 !important;
          font-size: 14px;
        }
      }
    }
  }

  .bank-cover {
    .bank-cover__logo {
      width: 24px !important;
      height: 24px !important;
    }

    .list-item__title.medium {
      margin-bottom: 0;
      line-height: auto;
    }

    .bank-cover__details {
      margin-left: 12px;
    }
  }

  .customer-payment-column {
    .row {
      margin-bottom: 4px;
    }

    .header {
      p {
        margin-top: 8px;
      }
    }

    .refund-button {
      margin-top: 16px;
      .button-icon--left {
        svg,
        path {
          fill: transparent !important;
        }
      }
    }

    .hr-divider {
      margin: 16px 0;
      width: 100%;
      background-color: $grey-variant-200;
      height: 1px;
    }
  }
}

.photo-modal {
  position: fixed;
  inset: 0;
  background: #222c3b29;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999999;

  &__show {
    display: flex;
  }

  .customer-img {
    height: 320px;
    width: 320px;
    object-fit: cover;
    border-radius: 99999px;
    border: 1px solid $grey-variant-300;
  }
}
