@mixin widget-wrapper {
  min-height: 486px;
  background-color: white;
}
@mixin remove-widget-wrapper {
  width: 100%;
  height: auto;
  border: none;
  box-shadow: none;
}

.builder-wigdet-container {
  width: 100%;
  height: 100%;

  p {
    letter-spacing: -0.1px;
  }
}

.widget-screen-container {
  width: 100%;
  height: calc(100% - 70px);
  background-color: $grey-variant-50;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  padding: 32px;

  .widget-window {
    @include display-flex(center, center, column);
    background-color: $white;
    height: 100%;
    width: 100%;
    border-radius: 16px;

    &__toolbar {
      height: convertPxToRem(26);
      border-bottom: 1px solid $grey-variant-50;
      margin-bottom: auto;
      width: 100%;
      @include display-flex(flex-start, center);
      gap: 10px;
      padding-left: 10px;

      span {
        display: block;
        width: convertPxToRem(8);
        height: convertPxToRem(8);
        border-radius: 8px;
        background-color: $grey-100;
      }
    }
  }

  .widget-screen {
    position: relative;
    transition: all 0.2s;
    border: 1px solid $grey-variant-200;
    overflow: hidden;
    margin-bottom: auto;

    .progress {
      height: 5px;
      width: 100%;
      background-color: $grey-100;
      @include display-flex(flex-start, center);

      span {
        width: 30%;
        display: block;
        height: 5px;
        background-color: $grey-800;
        border-radius: 10px;
      }
    }

    &__header {
      @include display-flex(flex-start, center);

      .close-svg {
        margin-left: auto;
        cursor: pointer;
      }

      .back-svg {
        margin-right: convertPxToRem(12);
        cursor: pointer;
      }
    }

    .widget {
      @include widget-wrapper;
      width: 100%;
    }

    .secure {
      font-size: 14px;
      text-align: center;
      padding: 16px;
      @include display-flex(center);
      color: white;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      strong {
        margin-left: 4px;
      }
    }
  }

  &.desktop {
    .widget-screen {
      width: convertPxToRem(536);
      border-radius: 11.15px;

      .widget,
      &__header {
        padding: 21px 43px;
      }
    }
  }

  &.mobile {
    .widget-window {
      background-color: transparent;

      &__toolbar {
        visibility: hidden;
      }
    }

    .widget-screen {
      width: convertPxToRem(320);
      border-radius: 12px;

      .widget,
      &__header {
        padding: 20px;
        background-color: white;
      }

      &__header {
        .close-svg {
          margin-left: auto;
          width: 20px;
          height: 20px;
        }

        .back-svg {
          margin-right: 0;
          width: 20px;
          height: 20px;
        }

        .logos-svg {
          margin-left: auto;
          transform: scale(1.2);

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.product-info-container {
  width: 100%;
  height: calc(100% - 70px);
  background-color: $grey-variant-50;
  @include display-flex(center, center, column);

  .content-container {
    @include display-flex(flex-start, center, column);
    width: 280px;

    p {
      text-align: center;
    }
  }

  p.semibold {
    margin-bottom: convertPxToRem(8);
    color: $grey-900;
  }

  p.body3 {
    text-align: center;
    color: $grey-700;
  }

  .product-info__illustration {
    margin-bottom: 40px;
  }
}

.okra-widget-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 9999px;

  &.fcLogo {
    margin-left: convertPxToRem(10);
  }
}

.branding-screen {
  width: 100%;
  height: 100%;

  .widget-info-box {
    border: 1px solid $grey-variant-100;
    border-radius: 8px;
    padding: 24px;
    margin-top: 40px;
    margin-bottom: 16px;

    &.mobile {
      svg {
        width: 24px;
      }
    }

    > div {
      display: flex;
      margin-bottom: convertPxToRem(22);

      .content {
        margin-top: -3px;
      }

      svg {
        margin-right: 16px;
        width: 16px;
        height: 16px;

        path {
          stroke: $grey-variant-500;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    margin-top: convertPxToRem(100);

    div {
      @include display-flex(center, center);

      p {
        color: $grey-300;
        margin-left: 7px;
        margin-right: 5px;
      }

      .okra-logo {
        width: 12.02px;
        height: 15.17px;
      }
    }

    button {
      width: 100%;
      margin-top: convertPxToRem(18);
    }
  }

  .agreement {
    font-size: 11px;
    text-align: center;
    line-height: 17px;
    width: 222px;
    margin: 17px auto 0 auto;
  }
}

.widget-button {
  background: $orange;
  box-shadow: 0 4px 8px rgba(57, 83, 123, 0.08);
  border-radius: 6px;
  @include display-flex(center);
  padding: 11px;
  color: white;
  cursor: pointer;
}

.auth-screen {
  @include display-flex(flex-start, flex-start, column);

  &.mobile {
    h4 {
      font-size: convertPxToRem(20);
    }

    .auth-screen__footer {
      p,
      span {
        font-size: convertPxToRem(10);
      }
    }

    .auth-screen__option {
      p.semibold {
        font-weight: convertPxToRem(14);
      }

      .option {
        margin-right: 6px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  h4 {
    margin-bottom: convertPxToRem(22);
  }

  p.subtitle {
    color: $dark-ash;
    margin-bottom: 24px;
  }
  &__option {
    cursor: pointer;
    @include display-flex();
    margin-bottom: 10px;
    border: 1px solid $grey-variant-100;
    border-radius: 8px;
    padding: 11px;
    width: 100%;

    > div {
      width: 100%;
      @include display-flex();
    }

    svg {
      width: 24px;
      height: 24px;
    }
    div.option {
      margin-right: 12px;
      padding: 8px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
    }
    p.caption {
      color: $grey-900;
    }

    .check {
      width: convertPxToRem(17);
      height: convertPxToRem(17);
      border-radius: 100px;
      border: 1px solid $grey-300;
      margin-left: auto;
    }
  }

  &__footer {
    margin-top: auto;
    width: 100%;
    p {
      text-align: center;
      color: $grey-variant-500;

      .link {
        color: $primary;
      }
    }

    button {
      width: 100%;
      margin-top: convertPxToRem(12);
    }
  }
}
.identity-screen {
  @include display-flex(space-between, center, column);
  .widget-button {
    margin-bottom: 40px;
    width: 100%;
  }
  &__info {
    text-align: center;
    .regular {
      font-size: 14px;
      margin-top: 6px;
    }
  }
}
.widget-screen-container.mobile {
  .identity-screen {
    .widget-button {
      margin-bottom: 0;
    }
  }
}
.banks-view-screen {
  h4 {
    margin-bottom: 16px;
  }

  .account-selector {
    @include display-flex(flex-start, center);
    border: 1px solid $light-grey;
    border-radius: 8px;
    padding: 11px;
    margin-bottom: 16px;

    p {
      margin-right: auto;
    }
    .icon {
      margin-right: convertPxToRem(11);
      @include display-flex(center, center);
    }
  }
}

.balance-screen {
  @include display-flex(flex-start, flex-start, column);
  width: 100%;

  .balance-container {
    height: 100%;
    width: 100%;

    &__header {
      @include display-flex(space-between, center);
      margin-top: convertPxToRem(15);
      margin-bottom: convertPxToRem(14);
      padding-bottom: convertPxToRem(10);
      border-bottom: 0.7px solid $grey-variant-100;
    }

    &__bank {
      @include display-flex(flex-start, center);
      gap: 11px;
      margin-bottom: convertPxToRem(14);

      .bank-icon {
        border-radius: 100px;
        svg {
          width: 24px;
          height: 24px;
          border-radius: 100px;
        }
      }
    }
    &__footer {
      width: 100%;
      margin-top: 200px;

      button {
        width: 100%;
      }
    }
  }
  .widget-button {
    margin: 16px 0;
  }

  .balance-account {
    p {
      margin-bottom: 2px;
      &:first-child {
        margin-bottom: 4px;
      }
    }
    p.amount {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .bank-container-list {
    @include display-flex(flex-start, center, column);
    gap: 10px;

    &__item {
      @include display-flex(space-between, center);
      width: 100%;
      padding: convertPxToRem(11);
      border: 1px solid $grey-variant-100;
      border-radius: convertPxToRem(9);
      height: 100%;

      .balance-check {
        @include display-flex(center, center);
        height: 100%;

        p {
          margin-top: convertPxToRem(24);
          margin-right: 16px;
          width: 100%;
        }
      }
    }
  }
}

.transactions-screen {
  svg {
    display: block;
    margin: auto;
  }
  .transactions-container {
    padding: 24px 16px;
    &.no-padding {
      padding: 0;
      > div {
        padding: 24px 16px;
      }
      div.below {
        padding-bottom: 24px;
        border-bottom: 1px solid $light-grey;
      }
    }
    margin: 24px 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(57, 83, 123, 0.08);
    border-radius: 8px;
    &__info {
      padding: 16px;
      border: 1px solid $light-grey;
      border-radius: 8px;
      margin: 16px 0;
      p.medium {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 24px;
      }
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        svg {
          width: 12px;
          height: 12px;
          margin: initial;
          margin-right: 10px;
          path {
            fill: $orange;
          }
        }
      }
    }
    .section {
      margin-top: 24px;
    }
    p.medium {
      // margin-bottom: 16px;
      font-size: 14px;
      line-height: 24px;
    }
  }
  hr {
    border-bottom: 1px solid $light-grey;
    height: 1px;
  }
  fieldset {
    padding: 4px 6px 6px 18px;
    border: 1.5px solid $orange;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top: 18px;
    margin-bottom: 14px;
    legend {
      // margin: 0 5px;
      padding: 0 4px;
      font-size: 12px;
    }
    p {
      font-size: 14px;
    }
  }
}

.success-screen {
  width: 100%;
  height: 100%;

  .success-container {
    height: 100%;
    @include display-flex(flex-start, center, column);

    &.mobile {
      p.body2 {
        width: 100%;
        padding: 0;
      }
      .success-container__footer {
        flex-wrap: wrap-reverse;
      }
    }

    .icon {
      margin-top: auto;
    }

    h4 {
      margin-bottom: convertPxToRem(16);
      margin-top: convertPxToRem(27);
    }

    p.body2 {
      color: $grey-2;
      text-align: center;
      width: convertPxToRem(351);
    }

    &__footer {
      margin-top: auto;
      @include display-flex(center, center);
      gap: convertPxToRem(16);
      width: 100%;

      &.mobile {
        flex-wrap: wrap-reverse;
      }

      button {
        width: 100%;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  > p.semibold {
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
  }
}

.account-selection {
  .bank-container-list__item {
    .radio.md-radio {
      input {
        display: default;
      }

      * {
        pointer-events: none;
      }

      &:hover {
        cursor: default !important;
        box-shadow: none;
      }
    }

    .checkbox.md-checkbox {
      input {
        display: default;
      }

      * {
        pointer-events: none;
      }

      &:hover {
        cursor: default !important;
        box-shadow: none;
      }
    }

    &.radio {
      &:first-of-type {
        background-color: $grey-50;

        .radio.primary-radio
          > label
          input:checked
          ~ .radio__display__wrapper
          .radio__display {
          background-color: $grey-900;
          border-color: $grey-900;

          &:hover {
            box-shadow: none;
            cursor: default;
          }
        }
      }
    }

    &.checkbox {
      background-color: $grey-50;

      .checkbox.primary-checkbox
        > label
        input:checked
        ~ .checkbox__display__wrapper
        .checkbox__display {
        background-color: $grey-900;
        border-color: $grey-900;

        &:hover {
          box-shadow: none;
          cursor: default;
        }
      }
    }
  }
}

.institution-view-screen {
  h4 {
    margin-bottom: convertPxToRem(22);
  }
  .institution-types-options {
    margin-top: convertPxToRem(30);
    &__item {
      @include display-flex(space-between, center);
      padding: convertPxToRem(9) 0;

      &.show-border {
        border-bottom: 1px solid $light-grey;
      }

      .bank-cover {
        &__logo {
          width: 24px !important;
          height: 24px !important;
          border: 1px solid $grey-variant-100;
          background-size: contain !important;
        }

        &__details {
          margin-left: convertPxToRem(16);

          p {
            color: $grey-900;
            font-size: convertPxToRem(16);
          }
        }
      }

      .okra-bank-details {
        margin-left: 16px;
        p {
          color: black;
        }
      }
    }
  }
}

.instant-payment-screen {
  @include display-flex(flex-start, flex-start, column);
  width: 100%;

  .instant-payment-container {
  }

  * {
    // outline: 1px dotted red;
  }

  .payment-reason,
  .payment-amount {
    width: 100%;

    .textfield-selector {
      padding-right: 24px;
    }
  }

  .divider {
    width: 100%;
    height: 0.7px;
    margin: 18px 0;
    background-color: $grey-variant-200;
  }

  span.caption {
    margin-top: 8px;
  }

  .subheading {
    font-size: 11px;
    color: $grey-variant-800;
  }

  span.bold {
    font-size: 11px;
    font-weight: 700;
  }

  .payment-footer {
    margin-top: auto;
    width: 100%;

    .total {
      @include display-flex(space-between, center);
      width: 100%;
    }

    .button {
      width: 100%;
      margin-top: 16px;
    }
  }
}
