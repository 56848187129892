$neutralborder: #dfe2e6;
$nuetralcolor: #737a91;

$light-yellow: #fffae8;
$dark-yellow: #ffa65c;

$light-ash: #e4e5e7;
$ash: #f6f7f9;
$ash-2: #fafbfd;
$dark-ash: #444650;
$gray: '#dfe2e6';

$deep-purple: #7422c5;
$light-purple: #efe6f8;

$light-green: #e6f6eb;
$light-green2: #adf799;
$okra-green: #009e74;
$orange: #d94f00;

$blue: #3e56ff;
$dark-blue: #54667f;

$okra-light-green: #e6f6eb;

$white: #fff;
$violet: #dbb8ff;

$icon-grey: #c6c8c9;
$neutral: #f5f6f7;

$status-tint: #e2f2ff;
$status-info: #0083ff;
$primary: $okra-green;
