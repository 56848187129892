.profile-card {
  overflow: hidden;
  &--children {
    @include display-flex(unset, unset);
    margin: 4em 1em;
    @include respond(tab-port) {
      display: block;
      margin: 4em 1em;
    }
  }
  &--right {
    width: 15.8em;
    @include respond(tab-port) {
      width: auto;
    }
  }
  &--left {
    width: 70%;
    @include respond(tab-port) {
      width: auto;
    }
  }
  &--card {
    padding: 0 !important;
    overflow: hidden;

    .card-header {
      background: $light;
      @include display-flex(flex-start, flex-end);
      height: 10em;
      .header-info {
        text-transform: capitalize;
        margin-left: 5em;
        margin-bottom: 1em;
        color: $black;
        @include respond(tab-port) {
          margin-left: 0.7em;
        }
      }
    }
  }
}
