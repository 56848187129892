@import './header';
@import './landing';
@import './custom';

.viewport-msg {
  display: none;
  @include respond(tab-land) {
    @include display-flex(flex-start, center, column);
    height: 100%;

    .icon {
      margin-top: convertPxToRem(40);
      margin-bottom: convertPxToRem(40);
      padding: 0 32px;
    }

    p.description {
      text-align: center;
      color: $grey-700;
      margin-top: convertPxToRem(8);
      padding: 0 32px;
    }

    h4 {
      color: $grey-900;
      padding: 0 32px;
    }

    &__footer {
      margin-top: auto;
      width: 100%;
      padding: 32px;

      button {
        width: 100%;
      }
    }
  }
}
.viewport-action {
  display: none;
  @media only screen and (min-width: 75em) {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.builder-edit {
  width: 100%;
  height: 100%;
}

.builder-container {
  width: 100%;
  height: 100%;
}

.builder {
  width: 100%;
  height: 100%;
  margin-top: 48px;
  position: relative;
  @include display-flex(space-between, flex-start);
}

.disabled-section-routes {
  font-size: convertPxToRem(14);
  color: $grey-400;
  height: convertPxToRem(38);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 2px;

  @include display-flex(flex-start, center);
  padding: 8px 12px;
  font-weight: 500;
}

.builder-multi {
  &-panel {
    display: flex;
    height: 100%;

    &__header {
      @include display-flex(flex-start, center);
      border-bottom: 1px solid $light-grey;

      padding-bottom: convertPxToRem(16);
      margin-left: convertPxToRem(16);
      margin-right: convertPxToRem(16);
      padding-top: convertPxToRem(24);

      margin-bottom: convertPxToRem(8);

      p {
        color: $grey-800;
        padding-left: convertPxToRem(16);
      }
    }

    &__left {
      padding: 0 12px;
      padding-top: 10px;
      width: 224px;
      background-color: $white;
      border-right: 1px solid $grey-variant-200;

      .product-sections {
        @include display-flex(flex-start, center, column);

        .section {
          width: 100%;
          padding: 6px 0;

          caption {
            width: 100%;
            display: flex;
            padding: 8px 12px;
            text-transform: uppercase;
            color: $grey-400;
          }

          .section-routes {
            @include display-flex(flex-start, center, column);

            a {
              font-size: convertPxToRem(14);
              color: $grey-800;
              height: convertPxToRem(38);
              border-radius: 8px;
              width: 100%;
              margin-bottom: 2px;

              @include display-flex(flex-start, center);
              padding: 8px 12px;
              font-weight: 500;

              &.active {
                color: $green-700;
                background-color: $green-50;
                font-weight: 600;
              }

              &:hover {
                background-color: $grey-variant-50;
              }
            }
          }
        }
      }
    }
    &__right {
      transition: all 0.2s;
      width: 360px;
      border-right: 1px solid $grey-variant-200;
    }
  }

  .builder-wigdet-container {
    width: calc(100% - 360px + 224px);
    z-index: 9;
  }

  .builder-extra-screen {
    overflow-y: scroll;
    height: 100%;
    position: relative;
    padding-bottom: 70px;

    &.all,
    &.custom,
    &.default {
      max-width: 368px;
    }

    p {
      font-size: 14px;
      color: $dark-grey;
    }
    label {
      display: block;
      margin-bottom: 8px;
    }
    .close-drawer {
      position: absolute;
      right: 24px;
      top: 12px;
      cursor: pointer;
    }

    &.closed {
      width: 64px;
      .content {
        display: none;
      }
    }
  }
}

.layout-toggle {
  @include display-flex(center, center);

  position: absolute;
  right: 0;
  width: convertPxToRem(24);
  height: convertPxToRem(24);
  border: 1px solid $grey-variant-200;
  z-index: 9999;
  margin-right: -10px;
  background-color: $white;
  border-radius: 99999px;
  margin-top: convertPxToRem(24);
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(34, 44, 59, 0.1);

  svg {
    width: 12px;
    height: 12px;
  }
}

@import './screens';
@import './branding';
@import './product';
@import './institutions';
