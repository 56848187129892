@import './PaymentLinkForm/payment-link-form';

.single-link__header {
  @include display-flex();
  padding: 0 0 24px;

  @include respond(tab-port) {
    display: block;
  }

  .link-header {
    &__title {
      color: $black;
    }

    &__actions {
      display: flex;

      @include respond(tab-port) {
        margin-top: 8px;
      }

      .button {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.single-link-container {
  .infocard {
    margin-bottom: 16px;

    .card-header {
      @include display-flex();
      padding: 0 0 16px;
      border-bottom: 1px solid $light-grey;
      color: $black;

      @include respond(tab-port) {
        display: block;
      }

      p {
        margin: 0;
      }

      .input-container {
        width: auto;

        .textfield-container {
          border: 0;
          height: 24px;

          @include respond(tab-port) {
            padding: 0;
          }

          input {
            font-weight: 500;
            color: $black;
          }

          .input-addon {
            font-size: 12px;
            font-weight: 600;
            border-left: 0;
            border-right: 1px solid $light-grey;
            padding: 0 24px;
            margin-right: 24px;

            &:last-child {
              border-right: 0;
              padding: 0;
              margin: 0;
            }
          }
        }

        &__focused {
          .textfield-container {
            box-shadow: none;
          }
        }
      }
    }

    // .card-content {
    //   & > div {
    //     border-right: 1px solid $light-grey;
    //     padding: 24px 24px 24px 0;
    //     margin-right: 24px;
    //     width: 100%;

    //     @include respond(tab-land) {
    //       width: 50%;
    //     }

    //     @include respond(tab-port) {
    //       width: 100%;
    //       margin-right: 0;
    //       border-right: 0;
    //     }

    //     &:last-child {
    //       border-right: 0;
    //       padding-right: 0;
    //       margin-right: 0;
    //     }
    //   }
    // }
  }

  .link-details-container {
    display: flex;

    @include respond(tab-port) {
      display: block;
    }

    & > div {
      width: 100%;
      max-width: 50%;

      @include respond(tab-port) {
        max-width: none;
      }
    }

    .link-details-items {
      .link-detail {
        @include display-grid(2, 1fr);
        align-items: flex-start;
        grid-gap: 20px;
        margin-bottom: 16px;

        p {
          margin: 0;
        }

        .toggle-container--boxed {
          .toggle-display-container {
            padding: 0;
            border: 0;
            justify-content: flex-start;

            .toggle-display {
              margin-left: 16px;
              width: 28px;
              height: 16px;

              .toggle-indicator {
                @include full-circle(12px);
              }
            }
          }

          &.active {
            .toggle-display-container {
              box-shadow: none;

              .toggle-indicator {
                left: calc(100% - 12px);
              }
            }
          }
        }
      }
    }
  }

  .payment-widget {
    margin-top: 32px;

    .snippet {
      margin-top: 16px;

      code {
        white-space: unset !important;
      }
    }
  }

  .link-qr {
    margin-top: 32px;

    &-content {
      padding: 20px 24px;
      border: 1px solid $light-grey;
      border-radius: 8px;
      @include display-flex();

      @include respond(tab-port) {
        display: block;
      }

      & > div {
        @include display-flex(flex-start);
      }

      .primary-button {
        @include respond(tab-port) {
          margin-top: 8px;
        }
      }
    }
  }
}

.share-link-modal {
  .textarea-container {
    margin-top: 24px;
  }
}

.links-copy {
  display: flex;
  align-items: center;

  &__text {
    @include ellipsis;
    max-width: 150px;
  }

  &__icon {
    display: flex;
    cursor: pointer;
    margin-left: 5px;
  }
}
