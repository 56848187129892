.table-container table tr td:last-child .table-products {
  max-width: 1000px;
}
.table-products {
  // max-width: 280px;
  // min-width: 280px;
  max-width: 293px;
  min-width: 293px;
  max-height: 34px;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
  &.active {
    max-height: 64px;
  }
  div.pill {
    & > svg.show-refresh {
      height: 0;
      opacity: 0;
      width: 0;
      // margin-left: auto; //terrible idea
      transition: all 0.2s linear;
    }
    &:hover:not(.neutral-pill) {
      cursor: pointer;
      background: $primary;
      color: $white;
      &.critical-pill {
        background: $critical;
      }
      & > svg.show-refresh {
        height: auto;
        width: auto;
        opacity: 1;
        margin-left: 8px;
        color: $white;
        path {
          stroke: $white;
        }
      }
    }
  }
  div:first-child {
    margin-left: 0;
  }
}
svg.show-active-refresh {
  color: $primary;
  path {
    stroke: $primary;
  }
}
.infocard-table {
  section:not(:last-child) {
    margin-bottom: 32px;
  }
  &__row {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 280px 1fr;

    > p:first-child {
      color: $dark-grey;
    }

    p,
    .bank-name {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 0;
    }

    @include respond(tab-land) {
      grid-template-columns: 200px 1fr;
    }
    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }

    .inline {
      @include display-flex(flex-start);
      p {
        margin-right: 16px;
      }
    }

    .product-pill {
      display: grid;
      grid-template-columns: 280px 1fr;
      margin-bottom: 16px;

      p {
        text-transform: capitalize;
      }
    }
  }
}

.inner-record-container {
  p {
    word-wrap: break-word;
  }
  .infocard {
    margin: 24px 0 32px 0;

    @include respond(phone) {
      margin: 0;
      padding: 0;
    }

    .medium.top {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid $light-grey;
    }

    .billable-products {
      margin-top: 32px;
    }
  }
  .split {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    &__first {
      width: 260px;
    }
    &__second {
      width: 180px;
    }
  }
  .title {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .semibold {
    font-size: 18px;
  }
  .subtitle {
    margin: 1rem 0;
  }
  .accounts-cover {
    display: flex;
    flex-wrap: wrap;

    .single-account {
      border: 1px solid $light-grey;
      padding: 1rem 3rem 0rem 1rem;
      border-radius: 10px;
      &:not(:last-child) {
        margin-right: 3rem;
      }
      @include respond(tab-land) {
        margin-bottom: 2rem;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
}

.records-retry {
  .okra-popup__body {
    max-height: 500px;
    overflow-y: auto;
  }
  .okra-popup__content {
    width: 41rem;
    @include respond(tab-land) {
      width: 51rem;
    }
    @include respond(phone) {
      width: 100%;
    }
  }
  .confirm {
    h4 {
      margin-bottom: 20px;
    }
    &Details {
      border: 1px solid $neutralborder;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      &Flex {
        display: grid;
        align-items: center;
        margin-bottom: 16px;
        grid-template-columns: repeat(2, minmax(180px, 220px));
        grid-column-gap: 10px;
        margin-bottom: 16px;

        p {
          font-size: 14px;
          // line-height: 17px;
          // max-width: 17.5rem;
          word-wrap: break-word;
          // text-overflow: ellipsis;
          &.isGrey {
            color: $nuetralcolor;
          }
        }
      }
      .pill {
        text-transform: capitalize;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &Product {
      li {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
