.billing {
  &--margin {
    &.top-1 {
      margin-top: 1em;
    }
    &.bottom-1 {
      margin-bottom: 1em;
    }
    &.top-2 {
      margin-top: 2em;
    }
    &.bottom-2 {
      margin-bottom: 2em;
    }
  }

  &__plans-cards {
    margin-top: 1.2em;
    padding: 0 !important;
    border: 1px $light-grey solid;

    .card-content {
      @include display-flex(space-between);
      @include respond(tab-land) {
        display: block;
      }
      .card-desc {
        margin: 1em 0;
        color: $dark-grey;
      }
    }
    .plans-card {
      height: 540px;
      .card-header {
        @include display-flex(unset);
        h5 {
          margin-right: 1em;
        }
      }
      padding: 2em;
      &:nth-child(2) {
        border-color: $light-grey;
        border-width: 0 1px 0 1px;
        border-style: solid;
      }
      @include respond(tab-land) {
        height: auto;
        &:nth-child(2) {
          border-width: 1px 0 1px 0;
        }
      }
    }
    .plans-btn {
      margin-bottom: 2em;
    }
    .plans-ul {
      li {
        margin: 1em 0;
        @include display-flex(flex-start, center);
      }
      .li-span {
        margin-left: 0.7em;
      }
    }
  }

  &__plans-pricing {
    margin-top: 2em;
    .select-container {
      margin-top: 2em;
    }
    .pricing-progress {
      margin: 3.5em 0 3em;
    }
    .pricing-card {
      padding: 0 !important;
      border: 1px $light-grey solid;
    }
    .content {
      @include display-flex(space-between, baseline);
      @include respond(tab-land) {
        display: block;
      }
      .card-item {
        width: 50%;
        padding: 1.5em;
        .card-item-title {
          margin-top: 0.4em;
          .small {
            color: $dark-grey;
          }
        }
        border-color: $light-grey;
        &.add-border {
          border-style: solid;
          border-width: 0 1px 0 0px;
        }
        @include respond(tab-land) {
          width: auto;
          &:nth-child(2) {
            border-style: solid;
            border-width: 1px 0 0 0;
          }
          &:nth-child(1) {
            border-width: 0;
          }
        }
      }
    }

    .pricing-flex {
      @include display-flex(space-between);
      &.port {
        @include respond(tab-port) {
          display: block;
          #cross {
            margin: 1em 5em 1em;
          }
        }
      }
    }
    .wallet-card {
      #cross {
        margin: 1em;
      }

      h4 {
        margin-top: 0.3em;
      }
      #save-loader {
        width: 4em;
        vertical-align: middle;
        margin: 0 0.5em;
      }
    }
    .product-toggle {
      padding: 1em;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    .row {
      @include display-flex(unset, unset);
      .col {
        flex: 1;
      }
    }
    .tabs {
      border-radius: 8px;
      overflow: hidden;
    }
    .tab {
      width: 100%;
      overflow: hidden;
      text-transform: capitalize;
      .no-caps {
        text-transform: none;
      }

      &-label {
        @include display-flex(space-between);
        padding: 1em 0;
        margin-bottom: 1em;
        border-bottom: 1px $light-grey solid;
        cursor: pointer;
        &::after {
          transform: rotate(90deg);
          content: '\276F';
          color: $dark-grey;
          width: 1em;
          height: 1em;
          text-align: center;
          transition: all 0.35s;
        }
      }
      &-content {
        max-height: 0;
        padding: 0 1em;
        transition: all 0.35s;
        @include display-flex(space-between, unset);
        p {
          &:nth-child(2) {
            margin-right: 2em;
            @include respond(tab-port) {
              margin-left: 1em;
              margin-right: unset;
            }
          }
        }
      }
    }
    input:checked {
      + .tab-label {
        &::after {
          transform: rotate(-93deg);
        }
      }
      ~ .tab-content {
        max-height: 100vh;
        padding: 0.3em 0;
      }
    }
  }
}
