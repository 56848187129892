.receipt-pdf {
  padding: 40px;
  width: 210mm;
  min-height: 297mm;
  margin: 0 auto;
  position: fixed;

  p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01px;
    color: $black;
    margin-bottom: 8px;
  }

  &-logo {
    @include display-flex(unset, flex-start);
    margin-bottom: 18px;

    img {
      width: 32px;
    }
  }

  &-header {
    @include display-flex(space-between, flex-start);
    & > div {
      margin-bottom: 8px;
    }

    span {
      font-size: 10px;
      line-height: 17px;
      color: $dark-grey;
    }

    &__value {
      color: $black;
      display: inline-flex;
      margin-left: 16px;
    }
  }

  &-recipient {
    margin: 40px 0 80px;
    font-size: 12px !important;
    span {
      display: flex;
      margin-bottom: 8px;
    }
  }

  &-table {
    font-size: 10px;
    line-height: 17px;
    @include display-flex(unset, unset, column);

    &__header {
      padding: 8px 16px;
      @include display-flex();
      color: $dark-grey;
    }

    &__body,
    &__footer {
      border-top: 1px solid $light-grey;
      background: #f7f9fc;
      padding: 8px 16px;
      @include display-flex();
      color: $black;
    }

    &__footer {
      width: 260px;
      align-self: flex-end;
    }

    &__value {
      color: $black;
    }
  }

  &-footer {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 40px;
    border-top: 1px solid $light-grey;
    padding: 8px 16px;
    font-size: 10px;
    line-height: 17px;
    color: $dark-grey;
  }
}
