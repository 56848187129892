.verification-drawer {
  &-header {
    display: flex;
    border-bottom: 1px solid $light-grey;
    margin: 24px;
    padding-bottom: 24px;
  }
  &-header,
  &-row {
    > svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      path {
        fill: $dark-grey;
      }
    }
  }
  &-row {
    margin: 24px 24px 24px 24px;
    display: flex;
    & > div {
      flex: 1;
      padding-bottom: 24px;
      border-bottom: 1px solid $light-grey;
    }
    &.last {
      > div {
        border-bottom: none;
      }
      .boxes {
        margin-top: 16px;
        @include display-flex();
        + p {
          margin-top: 16px;
        }
        & > div {
          flex: 1;
          &:first-child {
            margin-right: 24px;
          }
        }
      }
      .hidden-block > * {
        margin-bottom: 24px;
      }
      .hidden-block button {
        width: 100%;
      }
      .file-upload-block {
        .file-uploader {
          width: 100%;
          margin-top: 8px;
        }
        button {
          margin-top: 24px;
          width: 100%;
        }
        & > p {
          margin-top: 24px;
        }
      }
    }
  }
  &-upload-container {
    padding: 16px;
    border: 1px solid $light-grey;
    margin-top: 24px;
    button {
      width: 100%;
    }
    & > * {
      margin-bottom: 16px;
    }
    p.regular {
      font-size: 14px;
    }
    .row svg {
      width: 14px !important;
      height: 14px !important;
      margin-right: 8px !important;
    }
  }
  &-footer {
    @include display-flex(flex-end);
    border-top: 1px solid $light-grey;
    padding: 24px;
  }

  &-top {
    @include display-flex();
    &.spaced {
      margin-top: 24px;
      p.caption {
        margin: 4px 0;
        color: $dark-grey;
      }
    }
    .bold {
      font-weight: 500;
    }
  }
  span.p.medium {
    color: black;
  }

  .info {
    color: $dark-grey;
    margin-top: 4px;
    button {
      display: inline;
    }
    .sla-button {
      display: block;
      margin-top: 4px;
    }
  }
}

.home-header {
  margin-bottom: 24px;

  @include respond(phone) {
    font-size: 18px;
  }
}

// v2 version for home
.home-container-v2 {
  display: flex;
  gap: 30px;

  @media only screen and (max-width: 1376px) {
    flex-wrap: wrap;

    .divider {
      display: none;
    }

    .rhs {
      width: 100% !important;
    }
  }

  .button.link-button {
    padding: 0;
  }

  @include respond(tab-port) {
    flex-wrap: wrap;
  }

  .divider {
    width: 1px;
    background-color: $grey-variant-200;

    @include respond(tab-port) {
      display: none;
    }
  }

  .hr-divider {
    width: 100%;
    height: 1px;
    background-color: $grey-variant-200;
  }

  .card__header {
    padding-bottom: 24px;
    @include display-flex(flex-start, center);
    gap: 8px;
  }

  .card {
    background-color: white;
    border: 0.5px solid #dadcde;
    box-shadow: 0px 1px 0.5px rgba(34, 44, 59, 0.16),
      0px 0.5px 0px rgba(52, 64, 83, 0.12),
      0px 0.5px 0px rgba(68, 83, 104, 0.08);
  }

  .lhs {
    flex: 1;
    width: 100%;
    &-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }

    &_featured-cards {
      display: flex;
      gap: 24px;
      width: 100%;

      .card {
        cursor: pointer;
      }

      @include respond(tab-port) {
        padding: 0 42px;
      }

      @include respond(tab-port) {
        overflow-x: scroll;
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
        padding-bottom: 2px;
        gap: 16px;
        margin-bottom: 18px;
        scroll-snap-type: x mandatory;
        scroll-padding: 16px;

        &::-webkit-scrollbar {
          display: none;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      }

      .featured-card {
        width: 100%;
        min-width: 356px;
        scroll-snap-align: start;

        @include respond(tab-port) {
          min-width: calc(100% - 20px);
          max-width: 350px;
        }
        .card {
          width: 100%;
        }

        .icon {
          margin-bottom: 40px;
        }

        .title {
          color: $grey-700;
          margin-bottom: 8px;
        }

        h4.bold {
          line-height: 36px;
          color: $grey-900;

          @include respond(phone) {
            font-size: 18px;
          }
        }

        .footer {
          margin-top: 16px;
          @include display-flex(space-between, center);

          button {
            .button-icon--left {
              svg {
                height: 12px;
                height: 12px;
              }
            }
          }

          .arrow-right {
            transform: rotate(90deg);
          }
        }
      }
    }

    &_guides {
      .button-text {
        font-weight: 600;
      }

      .guides {
        .guide {
          @include display-flex(flex-start, flex-start);
          padding-top: 24px;
          gap: 24px;

          &_icon {
            width: 24px;
            height: 24px;

            @include display-flex(center, center);
          }

          &_content {
            width: 100%;

            .title {
              color: $grey-900;
            }

            .subtitle {
              margin-top: 4px;
              margin-bottom: 16px;
              color: $grey-700;
            }

            .text-link {
              color: $green-700;

              .link-button-icon {
                display: none;
              }
            }

            .hr-divider {
              margin-top: 24px;
            }
          }
        }
      }
    }

    &_apikeys {
      .row {
        padding: 24px 0;
        @include display-flex(space-between, center);

        p.body2 {
          color: $grey-900;
        }

        @include respond(tab-land) {
          flex-wrap: wrap;

          .reveal-btn {
            width: 100%;
            margin-top: 12px;
          }

          p.body2 {
            font-weight: 400;
          }
        }

        code {
          color: $grey-700;
        }

        .copy-element {
          @include respond(tab-land) {
            margin-top: 8px;
          }
        }

        .secret-keys {
          margin-left: auto;
          margin-right: 16px;

          @include display-flex(flex-start, stretch);

          @include respond(tab-land) {
            justify-content: flex-start;
            align-items: flex-start;
            margin: 8px 0;
          }

          .vt-divider {
            display: flex;
            width: 1px;
            background-color: $grey-variant-200;
            margin: 0 15px;

            @include respond(tab-land) {
              height: 22px;
            }
          }
        }
      }
    }

    .info-callout {
      align-items: center;
      border-radius: 12px;
      margin: 0;

      @include respond(tab-land) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      p.body3 {
        margin-right: auto;
      }

      .primary-link-button {
        @include respond(tab-land) {
          margin-left: 30px;
        }
        .link-button-icon {
          display: none;
        }
      }
    }
  }

  .rhs {
    width: 384px;
    .link-button {
      .button-text {
        font-weight: 600;
      }
    }

    @include respond(tab-land) {
      width: 100%;
    }

    &_section {
      margin-bottom: 56px;
    }

    .section__header {
      @include display-flex(flex-start, center);
      gap: 8px;
      padding-bottom: 24px;

      h5 {
        margin-right: auto;
      }

      .link {
        .button-icon--right {
          svg {
            margin-top: 4px;
            path {
              stroke: $green-700;
              fill: transparent;
            }
          }
        }
      }
    }

    .tile {
      padding: 24px 0;

      .link-button {
        padding: 0;

        .button-text {
          text-align: left;
        }
      }

      &-categories {
        padding-bottom: 8px;
        @include display-flex(flex-start, center);
        gap: 4px;
      }

      p.date {
        margin-top: 8px;
        color: $grey-700;
        font-size: 12px;
      }

      a {
        .link-button-icon {
          display: none;
        }

        .button-text {
          color: $grey-800;
        }
      }
    }

    .resource {
      padding: 24px 0;

      .button-text {
        font-weight: 600;
      }

      &-title {
        margin-bottom: 4px;
      }

      .subtitle {
        color: $grey-700;
        margin-bottom: 16px;
        font-size: 14px;
      }

      .button {
        height: auto;
        padding: 0;

        .link-button-icon {
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          background: transparent;
        }
      }
    }
  }
}

.home-header__switch {
  @include display-flex(flex-start, flex-start);
  gap: 24px;
  padding: 0 18px;
}

.new-update-modal {
  position: relative;

  > div {
    padding: 24px;

    > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    > h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      margin: 24px 0 16px 0;
    }
  }

  > svg {
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &__title {
    font-weight: 700;
    color: $grey-900;
    font-size: 18px;
  }

  &__close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: 1px solid #dadcde;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    height: 32px;
    width: 32px;
    padding: 8px;
  }

  &__footer {
    border-top: 1px solid $light-grey;
    padding: 16px 24px;
    @include display-flex(flex-end);

    .button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__guide-list {
    padding-left: 12px;
    > li {
      font-size: 14px;
      color: $grey_700;
      font-weight: 400;
      list-style-type: disc;
      display: list-item;
      line-height: 22px;
      padding-left: 4px;

      &::marker {
        font-size: 8px;
        margin-bottom: 2px;
        text-indent: 2px !important;
      }
    }
  }
}
