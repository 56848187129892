.profile-uploader {
  &--avatar {
    @include respond(tab-port) {
      width: 11em;
    }
    width: 12em;
    .avatar-holder {
      position: relative;
      @include full-circle(12em);
      top: 4em;
      left: 3em;
      @include respond(tab-port) {
        @include full-circle(10em);
        display: block;
        left: 0;
      }
      background-color: $white;
      .loader {
        position: absolute;
        @include full-circle(11em);
        @include respond(tab-port) {
          @include full-circle(9em);
        }
        left: 0.5em;
        top: 0.5em;
        .shine {
          @include full-circle(11em);
        }
        .shine::before {
          content: '';
          display: block;
          @include full-circle(11em);

          background: linear-gradient(-60deg, $light 0%, $light 45%, $light 50%, $light 65%, $light 100%);
          animation-name: shine;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          background-repeat: no-repeat;
        }

        @keyframes shine {
          0% {
            background-position: -300px 0%;
          }
          100% {
            background-position: 300px 0%;
          }
        }
      }
      .gray-holder {
        position: absolute;
        background-color: $light;
        @include full-circle(11em);
        @include respond(tab-port) {
          @include full-circle(9em);
        }
        left: 0.5em;
        top: 0.5em;
        .pic {
          @include full-circle(11em);
          @include respond(tab-port) {
            @include full-circle(9em);
          }
          background-size: cover;
          background-position: center;
          background-blend-mode: multiply;
          vertical-align: middle;
          text-align: center;
          color: transparent;
          transition: all 0.3s ease;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            background-color: rgba($color: $dark-grey, $alpha: 0.7);
            z-index: 10000;
            color: $white;
            transition: all 0.3s ease;
            text-decoration: none;
          }

          span {
            display: inline-block;
            padding-top: 4.5em;
            padding-bottom: 4.5em;
            @include respond(tab-port) {
              padding-top: 2.5em;
            }
          }
        }

        input[type='file'] {
          display: none;
        }
        &:hover {
          background-color: rgba($color: $dark-grey, $alpha: 0.4);
        }
      }
    }
  }
}
