.balance-history {
  padding: 0 !important;
  margin-top: 32px;

  .row {
    padding: 18px;
    border-bottom: 1px solid $light-grey;
    @include display-flex();
  }
  @include respond(phone) {
    margin: 0;
    padding: 0;
  }
  h4 {
    margin: 0;
    padding: 0;
  }
  .graph {
    margin-top: 32px;
    padding: 18px 18px 18px 32px;
  }
}
