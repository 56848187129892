.payout-funds {
  .okra-popup {
    &__content {
      overflow: unset;
      min-height: auto;
    }

    &__body {
      padding: 0;
    }
  }

  &__header {
    @include display-flex();
    padding: 20px 24px;
    border-bottom: 1px solid $light-grey;

    h4 {
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: 0;
      font-family: $primary-font;
      font-weight: 500;
    }

    &__close,
    &--close {
      cursor: pointer;
      svg {
        width: 32px;
        height: 32px;
      }
      svg {
        g,
        path {
          stroke: $dark-grey;
        }
      }
    }
  }

  &__form {
    padding: 24px 24px 40px;

    &--title,
    &--desc,
    &--label {
      display: block;
    }

    &--title {
      margin-bottom: 8px;
    }

    &--desc {
      margin-bottom: 24px;
    }

    &--label {
      margin-bottom: 8px;
    }

    .select-container,
    .input-container {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .currency-input-container {
      .select-container {
        margin-bottom: 0;
      }
    }

    .payout-balance {
      padding: 24px;
      border: 1px solid $light-grey;
      border-radius: 6px;
      margin-bottom: 24px;

      h4 {
        margin-top: 8px;
      }
    }

    .payout-methods {
      .payout-method {
        margin-bottom: 16px;

        &__title {
          display: block;
          margin-bottom: 8px;
        }
      }
    }

    .deduction-method {
      margin-bottom: 40px !important;
    }

    .cashout-fees {
      margin: 16px 0 24px;
      padding-top: 16px;
      border-top: 1px solid $light-grey;

      & > div {
        @include display-flex(flex-start, flex-end);
      }

      &__value {
        margin-right: 5px;
        line-height: 1;
      }
    }

    .payout-account-info {
      border: 1px solid $light-grey;
      border-radius: 6px;
      box-shadow: 0px 4px 8px rgba(57, 83, 123, 0.08);
      @include display-grid(2);
      padding: 16px;
      margin-top: 4px;
    }
  }

  &__footer {
    border-top: 1px solid $light-grey;
    padding: 16px 24px;
    @include display-flex(flex-end);

    .button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
