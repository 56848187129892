@import './APIKeys/apiKeys';
@import './Webhook/webhook';

.developer_table {
  .loader-container {
    height: 500px;
    max-height: 500px;

    p {
      margin-top: 12px !important;
    }
  }
}

.table-sticky-fix-v2 {
  .table-container table tr th {
    top: 54px; // we need a fix for this @David TODO
  }
}
